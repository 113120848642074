import React, { useContext } from 'react';

import Check from 'components/Check';
import WalletContext from 'contexts/wallet';
import useInfo from 'hooks/useInfo';
import { isValidAddress } from 'lib/ethereum/utils';

const styles = {
  address: {
    background: 'var(--c-neutral-400)',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  check: { textAlign: 'center', marginTop: 60 } as const,
  content: { padding: '40px var(--double-and-a-half-unit)' },
  unlocked: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: 'var(--c-brand-600)',
    marginTop: 20,
    fontWeight: 600,
  } as const,
};

export const Main = () => {
  const { user, dict } = useInfo();
  const { load, wallet } = useContext(WalletContext)!;

  if (!user) return null;

  const { address, userPrivateKey } = user;

  if (!isValidAddress(address)) return <span>{dict.noWallet}</span>;

  return (
    <>
      <h1>{wallet ? dict.walletUnlocked : dict.walletLocked}</h1>
      <div style={styles.address}>{address}</div>
      {wallet ? (
        <div style={styles.check}>
          <Check />
          <div style={styles.unlocked}>{dict.walletUnlocked}</div>
        </div>
      ) : (
        <div style={styles.content}>
          {userPrivateKey ? (
            <button
              type="submit"
              onClick={() => {
                load({});
              }}
            >
              {dict.unlock}
            </button>
          ) : (
            dict.recoverWallet
          )}
        </div>
      )}
    </>
  );
};

export default Main;
