import React, { useState } from 'react';

import Ethereum from 'components/Ethereum';
import { Data } from 'components/Form';
import Starkware from 'components/Starkware';
import useInfo from 'hooks/useInfo';
import useLoadWallet from 'hooks/useLoadWallet';
import useReturnToWalletSettingsTab from 'hooks/useReturnToWalletSettingsTab';
import { DecryptionError } from 'lib/errors';

import WalletIsLocked from './WalletIsLocked';
import { Props } from './types';

interface Keys {
  public: string;
  private: string;
}

export const PrivateKey = ({ onSuccess }: Props) => {
  const loadWallet = useLoadWallet();
  const { dict } = useInfo();
  const [ethereumKeys, setEthereumKeys] = useState<Keys | null>(null);
  const [starkwareKeys, setStarkwareKeys] = useState<Keys | null>(null);
  const [showEthereumPrivateKey, setShowEthereumPrivateKey] =
    useState<boolean>(false);
  const [showStarkwarePrivateKey, setShowStarkwarePrivateKey] =
    useState<boolean>(false);

  const returnToWalletSettingsTab = useReturnToWalletSettingsTab();

  const close = () => {
    returnToWalletSettingsTab();
    setTimeout(onSuccess, 50);
  };

  const onSubmit = async ({ currentPassword }: Data<string>) => {
    try {
      const wallet = await loadWallet(currentPassword);
      if (wallet) {
        setEthereumKeys({
          public: wallet.ethereumWallet.address,
          private: wallet.ethereumWallet.privateKey,
        });
      }
      if (wallet?.starkwareWallet) {
        setStarkwareKeys({
          public: wallet.starkwareWallet.publicKey,
          private: wallet.starkwareWallet.privateKey,
        });
      }
      return undefined;
    } catch (e) {
      if (e instanceof DecryptionError)
        return { currentPassword: dict.passwordIsInvalid };
      throw e;
    }
  };

  if (ethereumKeys?.private) {
    return (
      <div className="scroll-dialog wallet-drawer">
        <div className="warning">{dict.privateKeyWarning}</div>
        <span className="group-label">
          <Ethereum />
          {dict.ethereumKeysTitle}
        </span>
        <span className="group-description">
          {dict.ethereumKeysDescription}
        </span>
        <div className="private-keys">
          <div className="private-key">
            <span className="private-key-label">
              {dict.ethereumPublicAddress}
            </span>
            <span className="private-key-value">{ethereumKeys.public}</span>
          </div>
          <div className="private-key">
            <span className="private-key-label">
              {dict.ethereumPrivateKey}
              <button
                type="submit"
                className="stroke small icon"
                onClick={() => setShowEthereumPrivateKey(v => !v)}
              >
                {showEthereumPrivateKey ? '🔐' : '🔓'}
              </button>
            </span>
            <span className="private-key-value">
              {showEthereumPrivateKey ? ethereumKeys.private : '*'.repeat(66)}
            </span>
          </div>
        </div>
        {starkwareKeys?.private && (
          <>
            <span className="group-label">
              <Starkware />
              {dict.starkwareKeysTitle}
            </span>
            <span className="group-description">
              {dict.starkwareKeysDescription}
            </span>
            <div className="private-keys">
              <div className="private-key">
                <span className="private-key-label">
                  {dict.starkwarePublicKey}
                </span>
                <span className="private-key-value">
                  {starkwareKeys?.public}
                </span>
              </div>
              <div className="private-key">
                <span className="private-key-label">
                  {dict.starkwarePrivateKey}
                  <button
                    type="submit"
                    className="stroke small icon"
                    onClick={() => setShowStarkwarePrivateKey(v => !v)}
                  >
                    {showStarkwarePrivateKey ? '🔐' : '🔓'}
                  </button>
                </span>
                <span className="private-key-value">
                  {showStarkwarePrivateKey
                    ? starkwareKeys?.private
                    : '*'.repeat(66)}
                </span>
              </div>
            </div>
          </>
        )}
        <button onClick={close} type="submit" className="stroke">
          {dict.close}
        </button>
      </div>
    );
  }

  return <WalletIsLocked onSubmit={onSubmit} />;
};

export default PrivateKey;
