import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Init, MessagingContext } from '@sorare/wallet-shared';
import CenteredLoadingBall from 'components/CenteredLoadingBall';
import defaultDict from 'lib/dict';

type User = Init['response']['result']['user'];
type Dict = Init['response']['result']['dict'];
type FeatureFlags = Init['response']['result']['featureFlags'];
type Info = Omit<Init['response']['result'], 'user' | 'dict' | 'featureFlags'>;

interface InfoContext {
  user: User;
  dict: Dict;
  info: Info;
  featureFlags: FeatureFlags;
  setUser: (user: User) => void;
  logOut: () => void;
}

const Context = createContext<InfoContext | null>(null);

interface Props {
  children: ReactElement;
}

export const InfoProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [dict, setDict] = useState<Dict | null>(null);
  const [info, setInfo] = useState<Info | null>(null);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});
  const { sendRequest } = useContext(MessagingContext)!;

  const logOut = useCallback(() => setUser(undefined), []);

  useEffect(() => {
    const getUser = async () => {
      const { result } = await sendRequest<Init>('init', {});
      setUser(result.user);
      setDict(Object.assign(defaultDict, result.dict));
      setFeatureFlags(result.featureFlags);
      setInfo(result);
    };

    if (user === null) getUser();
  }, [sendRequest, setUser, user]);

  useEffect(() => {
    if (info?.langDir) {
      const html = window.document.documentElement;

      html.dir = info.langDir;
    }
  }, [info]);

  if (user === null || dict === null || info === null)
    return <CenteredLoadingBall />;

  return (
    <Context.Provider
      value={{ dict, featureFlags, info, user, setUser, logOut }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
