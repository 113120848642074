import { Dict } from '@sorare/wallet-shared';

const defaultDict: Dict = {
  'passwordForgotten?': '',
  address: '',
  approveBank: '',
  approveMigrator: '',
  changePasswordTitle: '',
  depositAmount: '',
  depositFundsSubmit: 'Deposit',
  availableAmountForDeposit: 'Available funds for deposit',
  depositAvailableSoon:
    'Your deposit will be available in your balance in a moment',
  edit: '',
  errorTrackingBody: '',
  errorTrackingTitle: '',
  gasPrice: 'Gas Price (in Gwei)',
  gasPricePlaceholder: '',
  forgotPasswordTitle: 'Forgotten Password',
  forgotPasswordHelper: '',
  forgotPasswordEmailSent: '',
  generateWallet: '',
  insufficientFundsForDeposit: '',
  noFundsAvailable: '',
  orQuickAccess: '',
  recoverKeyTitle: '',
  resetPasswordTitle: '',
  signEthMigration: '',
  signInTitle: '',
  signMigration: '',
  signUpAgreePartnersOffers: '',
  signUpTerms: '',
  signUpTitle: '',
  transactionCost: '',
  updateWallet: '',
  cancel: 'Cancel',
  goBack: 'Go back',
  changePasswordSubmit: 'Change',
  currentPassword: 'Current Password',
  currentPasswordPlaceholder: 'Your current password',
  newPassword: 'New password',
  newPasswordPlaceholder: 'Your new password',
  confirmNewPassword: 'Confirm your new password',
  differentPasswords: 'Passwords are different',
  email: 'Email',
  emailUnconfirmed: 'not confirmed',
  emailUnconfirmedTitle: "You've not confirmed your account.",
  emailUnconfirmedBody:
    "We've re-sent the confirmation email. Please check your email to confirm your account.",
  ethereumKeysTitle: 'Ethereum Account',
  ethereumKeysDescription:
    'This is your Ethereum Wallet. You can send ETH (and only ETH) to the public address.',
  ethereumPrivateKey: 'Private key',
  ethereumPublicAddress: 'Public address',
  forgotPassword: 'Forgot password?',
  alreadyHaveAccount: 'Have an account?',
  dontHaveAccount: "Don't have an account?",
  iAgreeToShare:
    "I agree to share my information with Sorare's trusted partners for marketing and promotional purposes",
  iAgreeToTermsAndConditions: `I agree to Sorare's <terms>Terms and Conditions</terms> and that my personal data will be processed pursuant to the <privacy>Privacy Policy</privacy>.`,
  iAgreeToAgeLimit: 'I certify that I am 16 years old or older.',
  invalidCredentials: 'invalid credentials',
  isInvalid: 'is invalid',
  loading: 'Loading',
  navigationBack: 'Back',
  next: 'Next',
  nickname: 'Username',
  noWallet: "You don't have a wallet",
  optional: 'Optional',
  password: 'Password',
  passwordIsInvalid: 'Password is invalid',
  tooManyLoginAttempts: 'Too many login attempts. Please try again later',
  recoverWallet: 'Your wallet must be recovered',
  recoveryKey: 'Recovery key',
  required: 'Required',
  signIn: 'Sign in',
  signUp: 'Sign up',
  signUpEmail: 'Sign up with Email',
  signUpGoogle: 'Sign up with Google',
  signUpApple: 'Sign up with Apple',
  signUpSubtitle: 'Create your Sorare account',
  oAuthSeparator: 'or',
  submit: 'Submit',
  twoFaCode: '2FA Code',
  unlock: 'Unlock',
  unlockWalletPlaceholder: 'Enter your password',
  walletLocked: 'Wallet locked',
  walletUnlocked: 'Wallet unlocked',
  close: 'Close',
  privateKey: 'Your private key',
  privateKeyWarning:
    'Warning: Do not share your private keys, our support will never ask them. Anyone with your private key could steal all your ETH and Cards.',
  pwned:
    'This password previously appeared in a public data breach on another website. Please choose a stronger password.',
  levenshtein: 'too close to your email or username',
  length: 'should be at least 6 characters long',
  message: 'Message',
  signature: 'Signature',
  starkwarePrivateKey: 'Private key',
  starkwareKeysTitle: 'Starkware Account',
  starkwareKeysDescription:
    'This is your Sorare (Starkware) Wallet. This cannot receive ETH directly and is only used within Sorare.',
  starkwarePublicKey: 'Public key',
  youMustAcceptTermsAndConditions:
    'You must accept our Terms and Conditions and Privacy Policy',
  youMustAcceptAgeLimit: 'You must be 18 years old or older',
  iAgreeToShareV2:
    "I agree to share my information with Sorare's trusted partners listed <partnerslist>here</partnerslist> for marketing and promotional purposes",
  trustedPartners: 'Trusted Partners',
  trustedPartnersDesc1:
    'Sorare will only share information with trusted partners subject to your express consent.',
  trustedPartnersDesc2:
    'You will find the list of our trusted partners and more information on their practice below.',
  trustedPartnersDesc3:
    'You can consent or oppose to data sharing by checking / unchecking the boxes in front of each partner',
  privacyPolicy: 'Privacy policy',
  epl: 'Premier League',
  eredivisie: 'Eredivisie',
  laliga: 'LALIGA',
  mlb: 'MLB – Major League Baseball',
  mlbpa: 'MLBPA – Major League Baseball Players Association',
  mls: 'MLS – Major League Soccer',
  nba: 'NBA – National Basketball Association',
  nbpa: 'NBPA – National Basketball Players Association',
  eplPolicyUrl: 'https://www.premierleague.com/privacy-policy',
  eredivisiePolicyUrl: 'https://eredivisie.eu/privacy-statement',
  laligaPolicyUrl: 'https://www.laliga.com/en-GB/legal/politica-privacidad',
  mlbPolicyUrl: 'https://www.mlb.com/official-information/privacy-policy',
  mlsPolicyUrl: 'https://www.mlssoccer.com/legal/privacy-policy',
  mlbpaPolicyUrl: 'http://mlb.mlb.com/pa/info/privacy.jsp',
  nbaPolicyUrl: 'https://www.nba.com/privacy-policy',
  nbpaPolicyUrl: 'https://nbpa.com/privacypolicy',
  trustedPartnersHelper:
    'Once you have agreed to the sharing of your information with a specific partner, you can still withdraw your consent to the processing of your data by a specific partner at any time. In order to do so, please contact them directly by referring to their privacy policy listed above.',
};

export default defaultDict;
