import { fromBase64, toBase64 } from '@sorare/wallet-shared';

import { encodePublicKey } from '../curves/p256';
import { Base64String } from '../types';

// To compress - record the sign of the `y` point
// then remove the `y` point and encode the recorded
// sign in the first bit
/* eslint-disable no-bitwise */
export const ecPointCompress = (ecdhRawPublicKey: ArrayBuffer) => {
  const u8full = new Uint8Array(ecdhRawPublicKey);
  const len = u8full.byteLength;
  const u8 = u8full.slice(0, (1 + len) >>> 1); // drop `y`
  u8[0] = 0x2 | (u8full[len - 1] & 0x01); // encode sign of `y` in first bit
  return u8;
};
/* eslint-enable no-bitwise */

export const deriveSharedSecret = async (
  ecdh: CryptoKeyPair,
  publicKey: Base64String,
  ephemeralPublicKey: CryptoKey
) => {
  // Convert to a P256 `CryptoKey` object
  const importedPublicKey = await window.crypto.subtle.importKey(
    'raw',
    fromBase64(publicKey),
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    []
  );
  const secret = await window.crypto.subtle.deriveKey(
    {
      name: 'ECDH',
      public: importedPublicKey,
    },
    ecdh.privateKey,
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt']
  );

  // Convert from `CryptoKey` object to an ArrayBuffer
  const exportableEphemeralPublicKey = await window.crypto.subtle.exportKey(
    'raw',
    ephemeralPublicKey
  );

  const exportableSecret = await window.crypto.subtle.exportKey('raw', secret);

  // run a kdf to protect the secret key
  // 1. practical: https://crypto.stackexchange.com/questions/48047/can-a-raw-ecdh-shared-secret-be-used-directly-for-encryption
  // 2. https://wiki.openssl.org/index.php/Elliptic_Curve_Diffie_Hellman

  // note that the KDF here is X9.63
  // the ephemeral public key is used to make
  // the scheme CCA2
  // https://www.tic.itefi.csic.es/CIBERDINE/Documetos/Cryptologia%20-%20Security%20and%20practical%20considerations%20when%20implementing%20ECIES%20-%20v1.0.pdf
  // const concatSecret = Buffer.concat([
  //   Buffer.from(exportableSecret),
  //   Buffer.from([0x00, 0x00, 0x00, 0x01]),
  //   encodePublicKey(toBase64(new Uint8Array(exportableEphemeralPublicKey))),
  // ]);

  const encodedPublicKey = encodePublicKey(
    toBase64(new Uint8Array(exportableEphemeralPublicKey))
  );
  const concatSecret2 = new Uint8Array(
    exportableSecret.byteLength + 4 + encodedPublicKey.length
  );
  concatSecret2.set(new Uint8Array(exportableSecret));
  concatSecret2.set(new Uint8Array([0, 0, 0, 1]), exportableSecret.byteLength);
  concatSecret2.set(encodedPublicKey, exportableSecret.byteLength + 4);

  return window.crypto.subtle.digest('SHA-256', concatSecret2);
};

export const generateECDHKeyPair = async () =>
  window.crypto.subtle.generateKey(
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    ['deriveBits', 'deriveKey']
  );

export const generateBytes = (byteLength: number) => {
  const randomBytes = new Uint8Array(byteLength);
  window.crypto.getRandomValues(randomBytes);

  return randomBytes;
};
