import { useCallback, useContext } from 'react';

import { MessagingContext, Toggle } from '@sorare/wallet-shared';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;

  return useCallback(
    async (open: boolean) => sendRequest<Toggle>('toggle', { display: open }),
    [sendRequest]
  );
};
