import {
  addHexPrefix,
  bytesToHex,
  hexToBytes,
  stripHexPrefix,
} from '@ethereumjs/util';

import {
  BackupOwner,
  BackupPrivateKeyRecovery,
  CreateWalletRecovery,
  Wallet as IWallet,
  PrivateKeyRecovery,
  PrivateKeyRecoveryPayload,
  backupOwners,
  fromBase64,
  toBase64,
} from '@sorare/wallet-shared';
import {
  AuthorizationApproval,
  AuthorizationRequest,
  isAMangopayWalletTransferAuthorizationRequest,
  isAStarkexLimitOrderAuthorizationRequest,
  isAStarkexTransferAuthorizationRequest,
} from '@sorare/wallet-shared/src/contexts/messaging/authorizations';
import { ENCRYPTION_KEYS, EVERVAULT_CONFIG } from 'config';
import {
  decryptWithPassword,
  encryptWithPassword,
  encryptWithPublicKey,
  generateIV,
  generateSalt,
  recoverMessage,
  wrap,
} from 'lib/encryption';
import EthereumWallet from 'lib/ethereum';
import { Encrypter as EvervaultEncrypter } from 'lib/evervault';
import StarkwareWallet from 'lib/starkware';

export interface EncryptedPrivateKey {
  salt: string;
  iv: string;
  encryptedPrivateKey: string;
}

export interface WalletExport {
  ethereumAddress: EthereumAddress;
  starkwarePublicKey: string;
  userKeys: EncryptedPrivateKey;
  backupKeys: string;
  wallet: IWallet;
}

export const encodeKeys = (
  ethereumPrivateKey: string,
  starkwarePrivateKey: string
) => {
  const ethKey = hexToBytes(ethereumPrivateKey);
  const starkKey = hexToBytes(starkwarePrivateKey);

  const message = new Uint8Array(ethKey.length + starkKey.length);
  message.set(ethKey);
  message.set(starkKey, ethKey.length);

  return message;
};

const decodeKeys = (keys: Uint8Array) => {
  const ethereumPrivateKey = addHexPrefix(bytesToHex(keys.slice(0, 32)));
  const starkKey = keys.slice(32);

  if (starkKey.length === 0) throw new Error('missing stark key');

  const starkwarePrivateKey = addHexPrefix(bytesToHex(starkKey));

  return { ethereumPrivateKey, starkwarePrivateKey };
};

const formatEmail = (email: string) => email.trim().toLowerCase();

class Wallet {
  public static create() {
    const ethereumWallet = EthereumWallet.create();
    const starkwareWallet = StarkwareWallet.create();

    return new Wallet(ethereumWallet, starkwareWallet);
  }

  public static load(ethereumPrivateKey: string, starkwarePrivateKey: string) {
    const ethereumWallet = EthereumWallet.load(ethereumPrivateKey);
    const starkwareWallet = StarkwareWallet.load(starkwarePrivateKey);

    return new Wallet(ethereumWallet, starkwareWallet);
  }

  public static async decryptAsync(
    password: string,
    { salt, iv, encryptedPrivateKey }: EncryptedPrivateKey
  ) {
    const keys = await decryptWithPassword(
      password,
      salt,
      iv,
      encryptedPrivateKey
    );

    const { ethereumPrivateKey, starkwarePrivateKey } = decodeKeys(keys);

    return this.load(ethereumPrivateKey, starkwarePrivateKey);
  }

  public static async recoverAsync(
    aesGcm256Key: string,
    encryptedKeys: string,
    iv: string
  ) {
    const keys = await recoverMessage(aesGcm256Key, encryptedKeys, iv);
    const { ethereumPrivateKey, starkwarePrivateKey } = decodeKeys(keys);

    return this.load(ethereumPrivateKey, starkwarePrivateKey);
  }

  public static async restoreAsync(
    privateKeyRecoveryPayload: PrivateKeyRecoveryPayload,
    encryptionKeyTemp: string
  ) {
    const encryptedKeys = await recoverMessage(
      encryptionKeyTemp,
      privateKeyRecoveryPayload.payload,
      privateKeyRecoveryPayload.ivTemp
    );

    return Wallet.recoverAsync(
      stripHexPrefix(
        bytesToHex(fromBase64(privateKeyRecoveryPayload.encryptionKey))
      ),
      toBase64(encryptedKeys),
      privateKeyRecoveryPayload.iv
    );
  }

  public ethereumWallet: EthereumWallet;

  public starkwareWallet: StarkwareWallet;

  private salt: string;

  private IV: string;

  public constructor(
    ethereumWallet: EthereumWallet,
    starkwareWallet: StarkwareWallet
  ) {
    this.ethereumWallet = ethereumWallet;
    this.starkwareWallet = starkwareWallet;
    this.salt = generateSalt();
    this.IV = generateIV();
  }

  private get encodedKeys() {
    return encodeKeys(
      this.ethereumWallet.privateKey,
      this.starkwareWallet.privateKey
    );
  }

  public async export(password: string, email: string): Promise<IWallet> {
    const userExport = await this.exportForUser(password);
    const privateKeyRecovery = await this.exportForEvervault('email', email);
    const backupPrivateKeyRecoveries = await Promise.all(
      backupOwners.map(async owner => this.exportForBackup(owner))
    );

    return {
      starkKeyWithPrefix: userExport.starkwarePublicKey,
      ethereumAddress: userExport.ethereumAddress,
      passwordEncryptedPrivateKey: {
        iv: userExport.userKeys.iv,
        payload: userExport.userKeys.encryptedPrivateKey,
        salt: userExport.userKeys.salt,
      },
      privateKeyRecovery,
      backupPrivateKeyRecoveries,
    };
  }

  public async exportForEvervault(
    recoveryMethod: CreateWalletRecovery['request']['args']['recoveryMethod'],
    recoveryDestination: string
  ): Promise<PrivateKeyRecovery> {
    const evervault = await EvervaultEncrypter.loadAsync(EVERVAULT_CONFIG);
    const { iv, cipher, encryptionKey } = await wrap(this.encodedKeys);

    const formattedRecoveryDestination =
      recoveryMethod === 'email'
        ? formatEmail(recoveryDestination)
        : recoveryDestination;

    const payload = await evervault.encrypt(
      JSON.stringify({
        encryptedPrivateKey: toBase64(cipher),
        recoveryMethod,
        recoveryDestination: formattedRecoveryDestination,
      })
    );

    return {
      appId: EVERVAULT_CONFIG.appId,
      encryptionKey: toBase64(encryptionKey),
      [recoveryMethod]: formattedRecoveryDestination,
      iv,
      payload,
      teamId: EVERVAULT_CONFIG.teamId,
    };
  }

  public async exportForBackup(
    owner: BackupOwner
  ): Promise<BackupPrivateKeyRecovery> {
    const { iv, cipher, encryptionKey } = await wrap(this.encodedKeys);
    const publicKey = ENCRYPTION_KEYS[owner];
    const encryptedSymmetricKey = await encryptWithPublicKey(
      publicKey,
      encryptionKey
    );

    return {
      encryptedSymmetricKey,
      iv,
      payload: toBase64(cipher),
      rsaEncryptionKey: owner,
      rsaPublicKey: publicKey,
    };
  }

  public async exportForUser(
    password: string
  ): Promise<Omit<WalletExport, 'backupKeys' | 'wallet'>> {
    const userKeys = await encryptWithPassword(
      password,
      this.salt,
      this.IV,
      this.encodedKeys
    );

    return {
      ethereumAddress: this.ethereumWallet.address,
      starkwarePublicKey: this.starkwareWallet.publicKey,
      userKeys: {
        salt: this.salt,
        iv: this.IV,
        encryptedPrivateKey: userKeys,
      },
    };
  }

  public approveAuthorizationRequest(
    authorizationRequest: AuthorizationRequest
  ): AuthorizationApproval {
    if (isAStarkexLimitOrderAuthorizationRequest(authorizationRequest)) {
      return {
        fingerprint: authorizationRequest.fingerprint,
        starkexLimitOrderApproval: {
          nonce: authorizationRequest.nonce,
          expirationTimestamp: authorizationRequest.expirationTimestamp,
          signature: this.starkwareWallet.signLimitOrder(authorizationRequest),
        },
      };
    }
    if (isAStarkexTransferAuthorizationRequest(authorizationRequest)) {
      return {
        fingerprint: authorizationRequest.fingerprint,
        starkexTransferApproval: {
          nonce: authorizationRequest.nonce,
          expirationTimestamp: authorizationRequest.expirationTimestamp,
          signature: this.starkwareWallet.signTransfer(authorizationRequest),
        },
      };
    }
    if (isAMangopayWalletTransferAuthorizationRequest(authorizationRequest)) {
      return {
        fingerprint: authorizationRequest.fingerprint,
        mangopayWalletTransferApproval: {
          nonce: authorizationRequest.nonce,
          signature:
            this.starkwareWallet.signMangopayWalletTransferAuthorizationRequest(
              authorizationRequest
            ),
        },
      };
    }

    throw new Error('Unsupported authorization request');
  }
}

export default Wallet;
