import { useContext, useEffect, useState } from 'react';

import {
  BackFromSignUpAdditionalScreen,
  MessagingContext,
  SignUpAdditionalScreen,
} from '@sorare/wallet-shared';
import Logo from 'atoms/icons/Logo';
import useInfo from 'hooks/useInfo';

export enum Partner {
  EPL = 'epl',
  EREDIVISIE = 'eredivisie',
  LALIGA = 'laliga',
  MLS = 'mls',
  NBA = 'nba',
  NBPA = 'nbpa',
  MLB = 'mlb',
  MLBPA = 'mlbpa',
}

export const partnersList = [
  Partner.EPL,
  Partner.EREDIVISIE,
  Partner.LALIGA,
  Partner.MLS,
  Partner.NBA,
  Partner.NBPA,
  Partner.MLB,
  Partner.MLBPA,
] as const;

type Props = {
  onBack: () => void;
  onSubmit: (agreeToShare: Partner[]) => void;
  agreeToShare: boolean;
  agreeToShareSpecific: Partner[];
};

export const PartnersList = ({
  onBack,
  onSubmit,
  agreeToShare,
  agreeToShareSpecific,
}: Props) => {
  const { dict } = useInfo();

  const { registerHandler, sendRequest } = useContext(MessagingContext)!;
  const [partnersOptins, setPartnersOptins] = useState<Partner[]>(
    agreeToShare ? partnersList.map(p => p) : agreeToShareSpecific
  );

  const togglePartner = (partner: Partner) => {
    if (partnersOptins.includes(partner))
      setPartnersOptins(partnersOptins.filter(p => p !== partner));
    else setPartnersOptins([...partnersOptins, partner]);
  };

  useEffect(() => {
    registerHandler<BackFromSignUpAdditionalScreen>(
      'backFromSignUpAdditionalScreen',
      async () => {
        onBack();
        return {};
      }
    );
  }, [onBack, registerHandler]);

  useEffect(() => {
    sendRequest<SignUpAdditionalScreen>('signUpAdditionalScreen', {});
  }, [sendRequest]);

  return (
    <div>
      <div className="form-header">
        <Logo width={120} />
        <div className="form-title">{dict.trustedPartners}</div>
        <p className="form-desc">{dict.trustedPartnersDesc1}</p>
        <p className="form-desc">{dict.trustedPartnersDesc2}</p>
        <p className="form-desc">{dict.trustedPartnersDesc3}</p>
      </div>
      <div className="partners-list">
        {partnersList.map(partner => (
          <div key={partner}>
            <label htmlFor={partner}>
              <input
                id={partner}
                type="checkbox"
                onChange={() => togglePartner(partner)}
                checked={partnersOptins.includes(partner)}
              />
              <span>
                {dict[partner]}
                &nbsp;-&nbsp;
                <a
                  target="_blank"
                  href={dict[`${partner}PolicyUrl`]}
                  rel="noreferrer"
                >
                  {dict.privacyPolicy}
                </a>
              </span>
            </label>
          </div>
        ))}
        <p className="form-helper">{dict.trustedPartnersHelper}</p>
      </div>
      <p>
        <button
          onClick={() => onSubmit(partnersOptins)}
          type="submit"
          className="blue"
        >
          {dict.submit}
        </button>
      </p>
    </div>
  );
};
