import React, {
  ReactElement,
  createContext,
  useCallback,
  useState,
} from 'react';

import { Deferred } from '@sorare/wallet-shared';
import Dialog from 'components/Dialog';
import { Data } from 'components/Form';
import ForgotPassword from 'forms/ForgotPassword';
import WalletIsLocked from 'forms/WalletIsLocked';
import useToggleWallet from 'hooks/useToggleWallet';

type Args = {
  fromDrawer: boolean;
  error?: string;
};
type GetValue = (args: Args) => [Promise<string | null>, () => void];

type CancelPrompt = () => void;

interface Context {
  getValue: GetValue;
  cancelPrompt: CancelPrompt;
}

const Context = createContext<Context | null>(null);

interface Props {
  children: ReactElement;
}

export const PromptProvider = ({ children }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const toggleWallet = useToggleWallet();
  const [deferred, setDeferred] = useState<Deferred<string | null>>(
    new Deferred()
  );

  const close = () => setOpen(false);

  const openPrompt = useCallback(
    ({ fromDrawer }: { fromDrawer: boolean }) => {
      setOpen(true);
      if (fromDrawer) toggleWallet(true);
    },
    [toggleWallet]
  );

  const getValue = useCallback<GetValue>(
    ({ fromDrawer, error: err }) => {
      const d = new Deferred<string | null>();

      if (err) setError(err);
      setDeferred(d);
      openPrompt({ fromDrawer });

      return [d.promise, close];
    },
    [openPrompt]
  );

  const submitPassword = async ({ currentPassword }: Data<string>) => {
    setError(null);
    deferred.resolve(currentPassword);
  };

  const cancelPrompt = useCallback(() => {
    setOpen(false);
    deferred.resolve(null);
  }, [deferred, setOpen]);

  const renderForm = () => {
    if (!open) return null;
    return (
      <WalletIsLocked
        presetErrors={{ currentPassword: error }}
        onSubmit={submitPassword}
      />
    );
  };

  const renderDialog = () => {
    if (!open) return null;

    return (
      <Dialog className="prompt">
        {showForgotPassword ? (
          <ForgotPassword
            onSuccess={() => {
              setShowForgotPassword(false);
            }}
          />
        ) : (
          renderForm()
        )}
      </Dialog>
    );
  };

  return (
    <Context.Provider value={{ getValue, cancelPrompt }}>
      {renderDialog()}
      {children}
    </Context.Provider>
  );
};

export default Context;
