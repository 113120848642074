export const gWeiToWei = (gwei: string) => {
  let [
    // eslint-disable-next-line prefer-const
    wholePart,
    decimalPart,
  ] = gwei.split('.');

  if (decimalPart) {
    if (decimalPart.length < 9) {
      decimalPart = `${decimalPart}${'0'.repeat(9 - decimalPart.length)}`;
    } else if (decimalPart.length > 9) {
      decimalPart = decimalPart.slice(0, 9);
    }
  } else {
    decimalPart = '0'.repeat(9);
  }
  return BigInt(`${wholePart}${decimalPart}`);
};
