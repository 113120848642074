const formatHex = (hex: string) => {
  const h = hex.includes('x') ? hex.slice(2) : hex;

  return h.replace(/^0+/, '').toLowerCase();
};

export const compareHex = (
  hex1: string | undefined,
  hex2: string | undefined
) => {
  const h1 = hex1 && formatHex(hex1);
  const h2 = hex2 && formatHex(hex2);

  return h1 === h2;
};
