import React from 'react';

import { MessagingContext, MessagingProvider } from '@sorare/wallet-shared';
import { ErrorFeedback } from 'components/ErrorBoundary/ErrorFeedback';
import Main from 'components/Main';
import { FormsProvider } from 'contexts/forms';
import { InfoProvider } from 'contexts/info';
import { PromptProvider } from 'contexts/prompt';
import { WalletProvider } from 'contexts/wallet';

import './styles.css';
import { ALLOWED_ORIGIN } from './config';
import { useMeasureScrollbars } from './hooks/useMeasureScrollbars';

const App = () => {
  useMeasureScrollbars();

  if (!ALLOWED_ORIGIN) return null;

  return (
    <MessagingProvider
      Context={MessagingContext}
      allowedOrigins={[ALLOWED_ORIGIN]}
      target={{ window: window.parent, origin: ALLOWED_ORIGIN }}
    >
      <ErrorFeedback>
        <InfoProvider>
          <PromptProvider>
            <WalletProvider>
              <FormsProvider>
                <Main />
              </FormsProvider>
            </WalletProvider>
          </PromptProvider>
        </InfoProvider>
      </ErrorFeedback>
    </MessagingProvider>
  );
};

export default App;
