import { useContext } from 'react';

import {
  MessagingContext,
  PrivateKeyRecoveryPayload,
  PromptRestoreWallet,
  RecoverKey as RecoverKeyRequest,
} from '@sorare/wallet-shared';
import Form, { Data } from 'components/Form';
import { currentPasswordWithLabel as currentPasswordField } from 'components/Form/fields';
import WalletContext from 'contexts/wallet';
import useHasher from 'hooks/useHasher';
import useInfo from 'hooks/useInfo';
import { DecryptionError } from 'lib/errors';
import Wallet from 'lib/wallet';

import { Props } from './types';

const fields = [
  currentPasswordField,
  [
    'recoveryKey',
    'password',
    {
      required: true,
      minLength: 64,
      maxLength: 64,
      pattern: '[0-9a-zA-Z]{64}',
      placeholder: 'recoveryKey',
      showLabel: true,
    },
  ],
] as const;

type Field = (typeof fields)[number][0];

export const RestoreWallet = ({
  privateKeyRecoveryPayload,
  privateKeyRecoveryPayloads,
  onSuccess,
}: PromptRestoreWallet['request']['args'] & Props) => {
  const { sendRequest } = useContext(MessagingContext)!;
  const { user, dict, setUser } = useInfo();
  const { load } = useContext(WalletContext)!;
  const hasher = useHasher();

  const payloads = privateKeyRecoveryPayloads || [privateKeyRecoveryPayload];

  const decryptWallet = async (
    recoveryKey: string,
    payload: PrivateKeyRecoveryPayload
  ) => {
    try {
      const wallet = await Wallet.restoreAsync(payload, recoveryKey);

      return wallet;
    } catch (e) {
      if (e instanceof DecryptionError) {
        return undefined;
      }

      throw e;
    }
  };

  const onSubmit = async ({ currentPassword, recoveryKey }: Data<Field>) => {
    const passwordHash = await hasher(currentPassword, user?.email);
    let wallet: Wallet | undefined;

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < payloads.length; i += 1) {
      wallet = await decryptWallet(recoveryKey, payloads[i]);
      if (wallet) break;
    }
    /* eslint-enable no-await-in-loop */

    if (!wallet) return { recoverKey: dict.isInvalid };

    const { userKeys: userPrivateKey } =
      await wallet.exportForUser(currentPassword);

    await load({ password: currentPassword, userPrivateKey, force: true });

    if (user) setUser({ ...user, userPrivateKey });
    const { error } = await sendRequest<RecoverKeyRequest>('recoverKey', {
      passwordHash,
      userPrivateKey,
    });

    if (error) return error;
    return undefined;
  };

  return <Form inputs={fields} onSubmit={onSubmit} onSuccess={onSuccess} />;
};

export default RestoreWallet;
