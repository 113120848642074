import { Input } from './types';

export const currentPassword: Input<'currentPassword'> = [
  'currentPassword',
  'password',
  {
    autoComplete: 'current-password',
    required: true,
    placeholder: 'unlockWalletPlaceholder',
    showLabel: false,
  },
];

export const currentPasswordWithLabel: Input<'currentPassword'> = [
  'currentPassword',
  'password',
  {
    autoComplete: 'current-password',
    required: true,
    placeholder: 'unlockWalletPlaceholder',
    showLabel: true,
  },
];
