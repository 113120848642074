import type { Web3 } from 'web3';

import { Deal, SettleDealSignatureType } from '@sorare/wallet-shared';

import {
  BANK_ADDRESS,
  ERC721_ADDRESS,
  ETH_MIGRATION_POOL_ADDRESS,
  MIGRATOR_ADDRESS,
  RELAY_ADDRESS,
  STARK_EXCHANGE_ADDRESS,
} from '../../config';

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
const ETH_ASSET_TYPE =
  '0xb333e3142fe16b78628f19bb15afddaef437e72d6d7f5c6c20c6801a27fba6';
export const ETH_ASSET_QUANTUM = '10000000000';

type ValueTypeParameter =
  | string
  | number
  | boolean
  | (string | number | boolean)[];

const encode = (web3: Web3, types: string[], values: ValueTypeParameter[]) =>
  web3.eth.abi.encodeParameters(types, values);

const dealBlob = (web3: Web3, deal: Deal) =>
  encode(
    web3,
    [
      'uint256',
      'address',
      'address',
      'uint256[]',
      'uint256[]',
      'uint256',
      'uint256',
      'address',
    ],
    [
      deal.dealId,
      deal.sender,
      deal.receiver || NULL_ADDRESS,
      deal.receiveTokenIds || [],
      deal.sendTokenIds || [],
      deal.sendAmountInWei?.toString() || '0',
      deal.minReceiveAmountInWei?.toString() || '0',
      deal.bankAddress,
    ]
  );

export const accountMappingMessage = (web3: Web3, mappedAddress: string) =>
  encode(
    web3,
    ['uint8', 'address', 'address'],
    [0, mappedAddress, BANK_ADDRESS]
  );

export function settleDealMessage(
  web3: Web3,
  deal: Deal,
  action: SettleDealSignatureType
) {
  return encode(web3, ['uint8', 'bytes'], [action, dealBlob(web3, deal)]);
}

const approveMessage = (
  web3: Web3,
  nonce: string | number,
  address: string,
  batchCall: boolean
) => {
  const extraGas = 47563;
  const encodedFunction = web3.eth.abi.encodeFunctionCall(
    {
      type: 'function',
      name: 'setApprovalForAll',
      inputs: [
        { name: 'operator', type: 'address' },
        { name: 'approved', type: 'bool' },
      ],
    },
    [address, 'true']
  );

  return {
    encodedFunction,
    extraGas,
    message: encode(
      web3,
      ['address', 'bytes', 'uint256', 'bool', 'address'],
      [ERC721_ADDRESS, encodedFunction, nonce, batchCall, RELAY_ADDRESS]
    ),
  };
};

export const approveBankMessage = (web3: Web3, nonce: string | number) => {
  return approveMessage(web3, nonce, BANK_ADDRESS, false);
};

export const approveMigratorMessage = (web3: Web3, nonce: string | number) => {
  return approveMessage(web3, nonce, MIGRATOR_ADDRESS, true);
};

export const migrateCardsMessage = (
  web3: Web3,
  cardIds: string[],
  expirationBlock: string | number
) => encode(web3, ['uint256[]', 'uint32'], [cardIds, expirationBlock]);

export const depositETHStarkExchangeTx = (
  web3: Web3,
  starkKey: string,
  vaultId: string,
  sorareAddress: string
) => {
  const data = web3.eth.abi.encodeFunctionCall(
    {
      type: 'function',
      name: 'depositEth',
      inputs: [
        { name: 'starkKey', type: 'uint256' },
        { name: 'assetType', type: 'uint256' },
        { name: 'vaultId', type: 'uint256' },
      ],
    },
    [starkKey, ETH_ASSET_TYPE, vaultId.toString()]
  );

  return {
    from: sorareAddress,
    to: STARK_EXCHANGE_ADDRESS,
    data,
  };
};

export const migrateEthMessage = (
  web3: Web3,
  dealId: string | number,
  sender: string,
  sendAmountInWei: bigint
) => {
  const deal: Deal = {
    dealId,
    sender,
    sendAmountInWei,
    receiver: ETH_MIGRATION_POOL_ADDRESS,
    bankAddress: BANK_ADDRESS,
  };

  return settleDealMessage(web3, deal, SettleDealSignatureType.SendETH);
};
