import { NO_KEY_ERROR } from 'constants/errors';

class NoKeyError extends Error {
  constructor() {
    super('user has no private key');
    this.name = NO_KEY_ERROR;
  }
}

export default NoKeyError;
