import React, { useContext } from 'react';

import {
  GeneratedKey,
  Wallet as IWallet,
  MessagingContext,
} from '@sorare/wallet-shared';
import Form, { Data } from 'components/Form';
import useCreateWallet from 'hooks/useCreateWallet';
import useHasher from 'hooks/useHasher';
import useInfo from 'hooks/useInfo';
import { DecryptionError } from 'lib/errors';
import { validate } from 'lib/password';
import Wallet from 'lib/wallet';

import { Props } from './types';

const fields = [['password', 'password']] as const;

type Field = (typeof fields)[number][0];

export const GeneratedKeys = ({ onSuccess }: Props) => {
  const { user, dict } = useInfo();
  const createWallet = useCreateWallet();

  const hasher = useHasher();
  const { sendRequest } = useContext(MessagingContext)!;

  if (!user) return null;

  const { email, nickname, userPrivateKey: key, address } = user;
  const isUpdate = address || key;

  const onSubmit = async ({ password }: Data<Field>) => {
    let data: IWallet;

    if (isUpdate) {
      try {
        const wallet = await Wallet.decryptAsync(password, key!);
        data = await wallet.export(password, email);
      } catch (e) {
        if (e instanceof DecryptionError) {
          return { password: dict.passwordIsInvalid };
        }
        throw e;
      }
    } else {
      const passwordError = await validate(email, nickname, password);
      if (passwordError) return { password: passwordError };

      data = await createWallet(password, email);
    }

    const passwordHash = await hasher(password, email);

    const { error } = await sendRequest<GeneratedKey>('generatedKey', {
      passwordHash,
      wallet: data,
    });

    if (error) return error;
    return {};
  };

  return (
    <Form
      inputs={fields}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      title={
        <p className="text-16">
          {isUpdate ? dict.updateWallet : dict.generateWallet}
        </p>
      }
    />
  );
};

export default GeneratedKeys;
