import { useCallback, useContext } from 'react';

import { MessagingContext, RequestResize } from '@sorare/wallet-shared';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;

  return useCallback(
    ({ height, width }: DOMRectReadOnly) => {
      if (height && width) {
        return sendRequest<RequestResize>('requestPlaceholderResize', {
          height,
          width,
          swallowIfNoHandler: true,
        });
      }
      return {};
    },
    [sendRequest]
  );
};
