import { DECRYPTION_ERROR } from 'constants/errors';

class DecryptionError extends Error {
  constructor(message?: string) {
    super(message || 'invalid password');
    this.name = DECRYPTION_ERROR;
  }
}

export default DecryptionError;
