import React, { useContext } from 'react';

import {
  ChangePassword as ChangePasswordRequest,
  MessagingContext,
} from '@sorare/wallet-shared';
import Form, { Data } from 'components/Form';
import WalletContext from 'contexts/wallet';
import useHasher from 'hooks/useHasher';
import useInfo from 'hooks/useInfo';
import { DecryptionError, NoKeyError } from 'lib/errors';
import { validate, validatePasswordConfirmation } from 'lib/password';
import { EncryptedPrivateKey } from 'lib/wallet';

interface Props {
  onSuccess: () => void;
  onForgotPassword: () => void;
}

const fields = [
  [
    'currentPassword',
    'password',
    { placeholder: 'currentPasswordPlaceholder' },
  ],
  ['newPassword', 'password', { placeholder: 'newPasswordPlaceholder' }],
  ['confirmNewPassword', 'password', { placeholder: 'newPasswordPlaceholder' }],
] as const;

type Field = (typeof fields)[number][0];

export const ChangePassword = ({ onSuccess, onForgotPassword }: Props) => {
  const { sendRequest } = useContext(MessagingContext)!;
  const { user, dict } = useInfo();
  const { load } = useContext(WalletContext)!;
  const hasher = useHasher();

  const loadWallet = async (password: string) => {
    try {
      const wallet = await load({ password });
      return wallet;
    } catch (e) {
      if (e instanceof NoKeyError) return null;
      throw e;
    }
  };

  const onSubmit = async ({
    currentPassword,
    newPassword,
    confirmNewPassword,
  }: Data<Field>) => {
    const differentPasswordsError = validatePasswordConfirmation(
      newPassword,
      confirmNewPassword
    );
    if (differentPasswordsError)
      return {
        newPassword: differentPasswordsError,
        confirmNewPassword: ' ',
      };

    const { email, nickname } = user!;
    const passwordError = await validate(email, nickname, newPassword);
    if (passwordError) return { newPassword: passwordError };

    let userPrivateKey: EncryptedPrivateKey | undefined;
    try {
      const wallet = await loadWallet(currentPassword);
      if (wallet) {
        ({ userKeys: userPrivateKey } =
          await wallet.exportForUser(newPassword));
      }

      const currentPasswordHash = await hasher(currentPassword, user?.email);
      const passwordHash = await hasher(newPassword);

      const { error } = await sendRequest<ChangePasswordRequest>(
        'changePassword',
        {
          currentPasswordHash,
          passwordHash,
          userPrivateKey,
        }
      );

      if (error) return error;
      return undefined;
    } catch (e) {
      if (e instanceof DecryptionError)
        return { currentPassword: dict.passwordIsInvalid };
      throw e;
    }
  };

  return (
    <div>
      <Form
        inputs={fields}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        submitLabel={dict.changePasswordSubmit}
      />
      <button
        onClick={onForgotPassword}
        type="button"
        className="passwordForgottenV2"
      >
        {dict.forgotPassword}
      </button>
    </div>
  );
};
export default ChangePassword;
