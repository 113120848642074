import { useCallback } from 'react';

import { Wallet } from '@sorare/wallet-shared';
import WalletNew from 'lib/wallet';

export default () => {
  return useCallback(async (password: string, email: string) => {
    const wallet = await WalletNew.create();

    return wallet.export(password, email) as Promise<Wallet>;
  }, []);
};
