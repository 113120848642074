import { addHexPrefix, hexToBytes, isHexPrefixed } from '@ethereumjs/util';

import { fromBase64, toBase64 } from '@sorare/wallet-shared';
import { DecryptionError } from 'lib/errors';

export const generateIV = () => {
  return toBase64(crypto.getRandomValues(new Uint8Array(12)));
};

export const generateSalt = () => {
  return toBase64(crypto.getRandomValues(new Uint8Array(16)));
};

export const recoverMessage = async (
  aesGcm256: string,
  encryptedMessage: string,
  iv: string
) => {
  try {
    const key = await crypto.subtle.importKey(
      'raw',
      // TODO enforce hex prefix
      isHexPrefixed(aesGcm256)
        ? hexToBytes(aesGcm256)
        : hexToBytes(addHexPrefix(aesGcm256)),
      {
        name: 'AES-GCM',
        length: 256,
      },
      false,
      ['decrypt']
    );

    const message = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: fromBase64(iv),
      },
      key,
      fromBase64(encryptedMessage)
    );

    return new Uint8Array(message);
  } catch (e) {
    throw new DecryptionError('invalid decryption key');
  }
};

export const deriveKeyFromPassword = async (password: string, salt: string) => {
  const key = await crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(password),
    'PBKDF2',
    false,
    ['deriveKey']
  );

  return crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: fromBase64(salt),
      iterations: 50_000,
      hash: { name: 'SHA-256' },
    },
    key,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt', 'decrypt']
  );
};

export const encryptWithPassword = async (
  password: string,
  salt: string,
  iv: string,
  message: Uint8Array
) => {
  const key = await deriveKeyFromPassword(password, salt);

  const cipher = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: fromBase64(iv), tagLength: 128 },
    key,
    message
  );

  return toBase64(new Uint8Array(cipher));
};

// wrap generates a random AES 256 bit key and uses it to encrypt the message
export const wrap = async (message: Uint8Array) => {
  const key = await crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );

  const iv = generateIV();

  const cipher = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: fromBase64(iv), tagLength: 128 },
    key,
    message
  );

  const encryptionKey = new Uint8Array(
    await crypto.subtle.exportKey('raw', key)
  );

  return {
    iv,
    cipher: new Uint8Array(cipher),
    encryptionKey,
  };
};

export const decryptWithPassword = async (
  password: string,
  salt: string,
  iv: string,
  cipher: string
) => {
  const data = fromBase64(cipher);
  const key = await deriveKeyFromPassword(password, salt);

  try {
    const privateKey = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: fromBase64(iv), tagLength: 128 },
      key,
      data
    );
    return new Uint8Array(privateKey);
  } catch (e) {
    throw new DecryptionError();
  }
};

// encryptWithPublic key takes an RSA public key in spki format and encrypts the message
export const encryptWithPublicKey = async (
  spki: string,
  message: Uint8Array
) => {
  const encryptionKey = fromBase64(spki);

  const key = await crypto.subtle.importKey(
    'spki',
    encryptionKey,
    { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
    false,
    ['encrypt']
  );

  const cipher = await crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    key,
    message
  );

  return toBase64(new Uint8Array(cipher));
};
