import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ApproveAuthorizationRequests,
  ApproveBank,
  ApproveMigrator,
  CancelUnlockScreen,
  CreateWalletRecovery,
  EncryptedPrivateKey,
  LoadWallet,
  LogOut,
  MessagingContext,
  SignEthMigration,
  SignLimitOrdersErrorCodes,
  SignMany,
  SignMigration,
  SignPaymentIntent,
  SignSettleDeal,
  SignTransfer,
  SignWalletChallenge,
  Verify2FA,
} from '@sorare/wallet-shared';
import PromptContext from 'contexts/prompt';
import useHasher from 'hooks/useHasher';
import useInfo from 'hooks/useInfo';
import useKeys from 'hooks/useKeys';
import useToggleWallet from 'hooks/useToggleWallet';
import { DecryptionError, NoKeyError } from 'lib/errors';
import { compareHex } from 'lib/utils';
import Wallet from 'lib/wallet';

interface Args {
  password?: string | null;
  userPrivateKey?: EncryptedPrivateKey;
  force?: true;
  error?: string;
  cb?: () => void;
}

type LoadWrapper<T> = (
  onSuccess: (w: Wallet, s: string) => Promise<T | null>,
  args?: Args
) => Promise<T | null>;

type Load = (args?: Args) => Promise<Wallet | null>;

type GetHashPasswordAndUnlockWalletArgs = {
  error?: never;
  unlockWallet?: true;
};

type GetHashPasswordWithoutUnlockWallet = {
  error?: string;
  unlockWallet: false;
};

type GetHashPasswordArgs =
  | GetHashPasswordAndUnlockWalletArgs
  | GetHashPasswordWithoutUnlockWallet;

type GetHashPassword = (args?: GetHashPasswordArgs) => Promise<string | null>;

interface WalletContext {
  load: Load;
  wallet: Wallet | null;
  getHashPassword: GetHashPassword;
  lock: () => void;
}

const Context = createContext<WalletContext | null>(null);

interface Props {
  children: ReactElement;
}

export const WalletProvider = ({ children }: Props) => {
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { getValue, cancelPrompt } = useContext(PromptContext)!;
  const hash = useHasher();
  const { sendRequest, registerHandler } = useContext(MessagingContext)!;
  const { dict, logOut, user } = useInfo();
  const toggleWallet = useToggleWallet();
  const getKeys = useKeys();

  const lock = useCallback(() => setWallet(null), []);

  const verify2FA = useCallback(
    async () => sendRequest<Verify2FA>('verify2FA', {}),
    [sendRequest]
  );

  const loadWallet = useCallback(
    async (secret: string, priv: EncryptedPrivateKey) => {
      try {
        timerRef.current = setTimeout(lock, 3600000);
        return await Wallet.decryptAsync(secret, priv);
      } catch (e) {
        if (e instanceof DecryptionError) {
          return null;
        }
        throw e;
      }
    },
    [lock]
  );

  const promptForPassword = useCallback<
    (
      args: Pick<Args, 'password' | 'error'>
    ) => Promise<[string | null, undefined | (() => void)]>
  >(
    async ({ password, error }) => {
      if (password) {
        return [password, undefined];
      }
      const [secretPromise, close] = getValue({
        error,
        fromDrawer: true,
      });
      return [await secretPromise, close];
    },
    [getValue]
  );

  const loadWrapper = useCallback<LoadWrapper<Wallet | string | null>>(
    async (
      onSuccess: (w: Wallet, string: string) => Promise<Wallet | string | null>,
      args: Args = {}
    ) => {
      const {
        password,
        userPrivateKey,
        force,
        error: promptInitialError,
        cb,
      } = args;
      if (wallet && !force) return wallet;
      let need2FA = false;
      let priv = userPrivateKey;
      if (!priv) {
        const { result, error } = await getKeys();
        if (error === 'invalid-otp') {
          need2FA = true;
        } else {
          priv = result.userPrivateKey;
        }
      }
      if (!priv && !need2FA) throw new NoKeyError();

      let prompt = true;
      let w = null;
      let secret: string | null;
      let close: (() => void) | undefined;
      let error: string | undefined = promptInitialError;
      /* eslint-disable no-await-in-loop */
      while (prompt) {
        [secret, close] = await promptForPassword({ error, password });
        if (secret === null) return null;
        if (!priv) {
          const twoFAresult = await verify2FA();
          if (twoFAresult.result?.userPrivateKey) {
            w = await loadWallet(secret, twoFAresult.result?.userPrivateKey);
          }
        } else {
          w = await loadWallet(secret, priv);
        }

        if (w) {
          prompt = false;
          if (close) close();
          if (cb) cb();
          return onSuccess(w, secret);
        }

        if (close) {
          error = dict.passwordIsInvalid;
        } else {
          throw new DecryptionError('');
        }
      }

      return null;
      /* eslint-enable no-await-in-loop */
    },
    [
      promptForPassword,
      dict.passwordIsInvalid,
      getKeys,
      wallet,
      loadWallet,
      verify2FA,
    ]
  );

  const load = useCallback<Load>(
    async (args = {}) => {
      const loadResult = await loadWrapper(async (w: Wallet) => {
        setWallet(w);
        return w;
      }, args);
      return loadResult as Wallet | null;
    },
    [loadWrapper]
  );

  const getHashPassword = useCallback<GetHashPassword>(
    async ({ unlockWallet, error } = { unlockWallet: true }) => {
      if (unlockWallet !== false) {
        const hashPassword = await loadWrapper(
          async (w: Wallet, secret: string) => {
            return hash(secret, user?.email);
          },
          {
            force: true,
          }
        );
        return hashPassword as string | null;
      }
      const secret = (await promptForPassword({ error }))[0];
      if (secret) {
        return hash(secret, user?.email);
      }
      return secret;
    },
    [promptForPassword, loadWrapper, hash, user]
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      wallet?.starkwareWallet &&
      user?.starkKey &&
      !compareHex(user.starkKey, wallet.starkwareWallet.publicKeyX)
    ) {
      lock();
    }
  }, [user?.starkKey, wallet?.starkwareWallet, lock]);

  useEffect(
    () =>
      registerHandler<LogOut>('logOut', async () => {
        lock();
        logOut();

        return {};
      }),
    [lock, logOut, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<SignSettleDeal>(
        'signSettleDeal',
        async ({ deal, action }) => {
          const w = await load({});
          if (!w) return { error: 'unable to load wallet' };

          const signature = w.ethereumWallet.signSettleDeal(deal, action);

          return { result: { signature } };
        }
      ),
    [load, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<LoadWallet>('loadWallet', async () => {
        const w = await load({});
        if (!w) return { error: 'unable to load wallet' };
        return { result: true };
      }),
    [load, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<ApproveAuthorizationRequests>(
        'approveAuthorizationRequests',
        async ({ authorizationRequests }) => {
          const w = await load({});
          if (!w) {
            return {
              error: {
                code: SignLimitOrdersErrorCodes.PROMPT_CANCELED,
                message: 'unable to load wallet',
              },
            };
          }

          try {
            const authorizationApprovals = authorizationRequests.map(request =>
              w.approveAuthorizationRequest(request)
            );
            return {
              result: {
                authorizationApprovals,
                starkKey: w.starkwareWallet!.publicKey,
              },
            };
          } catch (e) {
            return {
              error: {
                code: SignLimitOrdersErrorCodes.SIGNING_ERROR,
                message: (e as Error).message,
              },
            };
          }
        }
      ),
    [load, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<SignTransfer>('signTransfer', async ({ transfer }) => {
        const w = await load({});
        if (!w) return { error: 'unable to load wallet' };
        if (!w.starkwareWallet) return { error: 'no stark key' };

        const signature = w.starkwareWallet.signTransfer(transfer);

        return { result: { signature } };
      }),
    [load, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<SignPaymentIntent>(
        'signPaymentIntent',
        async ({ id, amount }) => {
          const w = await load({});
          if (!w) return { error: 'unable to load wallet' };
          if (!w.starkwareWallet) return { error: 'no stark key' };

          const signature = w.starkwareWallet.signPaymentIntent(id, amount);

          return { result: { signature } };
        }
      ),
    [load, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<SignWalletChallenge>(
        'signWalletChallenge',
        async ({ challenge }) => {
          const w = await load({});
          if (!w) return { error: 'unable to load wallet' };
          if (!w.starkwareWallet) return { error: 'no stark key' };

          const signature = w.starkwareWallet.signWalletChallenge(challenge);

          return { result: { signature } };
        }
      ),
    [load, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<SignMany>('signMany', async ({ values }) => {
        const w = await load({});
        if (!w) return { error: 'unable to load wallet' };
        if (!w.starkwareWallet) return { error: 'no stark key' };

        const signature = w.starkwareWallet.signMany(values);

        return { result: { signature } };
      }),
    [load, registerHandler]
  );

  useEffect(
    () =>
      registerHandler<ApproveBank>('approveBank', async ({ nonce }) => {
        const w = await load({
          cb: () => {
            toggleWallet(false);
          },
        });
        if (!w) return { error: 'unable to load wallet' };

        const result = w.ethereumWallet.signApproveBank(nonce);
        return { result };
      }),
    [dict.approveBank, load, registerHandler, toggleWallet]
  );

  useEffect(
    () =>
      registerHandler<ApproveMigrator>('approveMigrator', async ({ nonce }) => {
        const w = await load({
          cb: () => {
            toggleWallet(false);
          },
        });
        if (!w) return { error: 'unable to load wallet' };

        const result = w.ethereumWallet.signApproveMigrator(nonce);
        return { result };
      }),
    [dict.approveMigrator, load, registerHandler, toggleWallet]
  );

  useEffect(
    () =>
      registerHandler<SignMigration>(
        'signMigration',
        async ({ cardIds, expirationBlock }) => {
          const w = await load({
            cb: () => {
              toggleWallet(false);
            },
          });
          if (!w) return { error: 'unable to load wallet' };

          const signature = w.ethereumWallet.signMigrateCards(
            cardIds,
            expirationBlock
          );
          return { result: { signature } };
        }
      ),
    [dict.signMigration, load, registerHandler, toggleWallet]
  );

  useEffect(
    () =>
      registerHandler<SignEthMigration>(
        'signEthMigration',
        async ({ dealId, sendAmountInWei }) => {
          const w = await load({
            cb: () => {
              toggleWallet(false);
            },
          });
          if (!w) return { error: 'unable to load wallet' };

          const signature = w.ethereumWallet.signMigrateEth(
            dealId,
            sendAmountInWei
          );
          return { result: { signature } };
        }
      ),
    [dict.signEthMigration, load, registerHandler, toggleWallet]
  );

  useEffect(
    () =>
      registerHandler<CancelUnlockScreen>('cancelUnlockScreen', async () => {
        cancelPrompt();
        return {};
      }),
    [registerHandler, cancelPrompt]
  );

  useEffect(
    () =>
      registerHandler<CreateWalletRecovery>(
        'createWalletRecovery',
        async ({ recoveryMethod, recoveryDestination }) => {
          try {
            const w = await load({});
            if (!w) return { error: 'unable to load wallet' };
            const privateKeyRecovery = await w.exportForEvervault(
              recoveryMethod,
              recoveryDestination
            );
            if (privateKeyRecovery) {
              return { result: { privateKeyRecovery } };
            }
            return { error: 'Unable to create recovery key' };
          } catch (error) {
            if (typeof error === 'string') {
              return { error };
            }
            if (error instanceof Error) {
              return { error: error.message };
            }
            return { error: `${error}` };
          }
        }
      ),
    [registerHandler, load]
  );

  return (
    <Context.Provider value={{ load, wallet, lock, getHashPassword }}>
      {children}
    </Context.Provider>
  );
};

export default Context;
