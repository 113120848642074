import React, { ReactNode, useContext, useState } from 'react';

import { ErrorTracking, MessagingContext } from '@sorare/wallet-shared';
import { ErrorFallback } from 'components/ErrorBoundary/ErrorFallback';
import { ErrorBoundary } from 'components/ErrorBoundary/index';

export const ErrorFeedback = ({ children }: { children: ReactNode }) => {
  const { sendRequest } = useContext(MessagingContext)!;
  const [message, setMessage] = useState<{
    errorTitle: string;
    errorBody: string;
  }>({ errorTitle: '', errorBody: '' });
  const sendError = async (error: Error) => {
    const { result } = await sendRequest<ErrorTracking>('errorTracking', error);
    setMessage(result.message);
  };

  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorFallback message={message} />}
      onError={error => {
        sendError(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
