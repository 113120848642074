import {
  addHexPrefix,
  bytesToHex,
  hexToBytes,
  stripHexPrefix,
} from '@ethereumjs/util';

import { fromBase64 } from '@sorare/wallet-shared';

import { Base64String, Curve, HexString } from '../types';
import ASN1 from './asn1';

const buildEncoder = ({ p, a, b, seed, generator, n, h }: Curve) => {
  return (decompressedKey: HexString) => {
    const hexEncodedKey = ASN1(
      '30',
      ASN1(
        '30',
        // 1.2.840.10045.2.1 ecPublicKey
        // (ANSI X9.62 public key type)
        ASN1('06', '2A 86 48 CE 3D 02 01'),
        ASN1(
          '30',
          // ECParameters Version
          ASN1.UInt('01'),
          ASN1(
            '30',
            // X9.62 Prime Field
            ASN1('06', '2A 86 48 CE 3D 01 01'),
            // curve p value
            ASN1.UInt(p)
          ),
          ASN1(
            '30',
            // curve a value
            ASN1('04', a),
            // curve b value
            ASN1('04', b),
            // curve seed value
            ASN1.BitStr(seed)
          ),
          // curve generate point in decompressed form
          ASN1('04', generator),
          // curve n value
          ASN1.UInt(n),
          // curve h value
          ASN1.UInt(h)
        )
      ),
      // decompressed public key
      ASN1.BitStr(decompressedKey)
    );

    return hexToBytes(addHexPrefix(hexEncodedKey));
  };
};

/**
 * Given an EC curve name and its constants, generate a DER encoder for its compressed public keys
 * @param curveName
 * @param curveValues
 * @returns Function(decompressedPublicKey): base64EncodedString
 */
export const createCurve = (curveValues: Curve) => {
  const asn1Encoder = buildEncoder(curveValues);
  return (decompressedPublicKey: Base64String) => {
    return asn1Encoder(
      stripHexPrefix(bytesToHex(fromBase64(decompressedPublicKey)))
    );
  };
};
