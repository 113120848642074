import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  MessagingContext,
  PasswordForgotten,
  Prompt,
  PromptDeposit,
  PromptResetPassword,
  PromptRestoreWallet,
  PromptSignupWithUsername,
  SetMode,
  SignUpMobileView,
  Sport,
} from '@sorare/wallet-shared';
import Dialog from 'components/Dialog';
import SignIn from 'components/SignIn';
import ChangePassword from 'forms/ChangePassword';
import DepositStarkExchange from 'forms/DepositStarkExchange';
import ForgotPassword from 'forms/ForgotPassword';
import GenerateKey from 'forms/GenerateKey';
import GenerateKeys from 'forms/GenerateKeys';
import PrivateKey from 'forms/PrivateKey';
import ResetPassword from 'forms/ResetPassword';
import RestoreWallet from 'forms/RestoreWallet';
import useInfo from 'hooks/useInfo';
import useRegisterPassword from 'hooks/useRegisterPassword';
import { SignUp } from 'src/forms/SignUp';

interface Context {
  openedForm?:
    | Prompt['request']['args']['type']
    | 'resetPassword'
    | 'restoreWallet';
  close: () => void;
}

const FormsContext = createContext<Context | null>(null);

type OpenedFormName = Context['openedForm'];

interface Props {
  children: ReactNode;
}

export const FormsProvider = ({ children }: Props) => {
  const { registerHandler, sendRequest } = useContext(MessagingContext)!;
  const { user } = useInfo();
  const [openedForm, setOpenedForm] = useState<OpenedFormName>(undefined);
  const [backTo, setBackTo] = useState<OpenedFormName>(undefined);
  const [mobileApp, setMobileApp] = useState<boolean>(false);
  const [depositId, setDepositId] = useState<string | undefined>(undefined);
  const [nickname, setNickname] = useState<string>();
  const [sport, setSport] = useState<Sport>();
  const [isAndroidApp, setIsAndroidApp] = useState<boolean>(false);
  const [resetPasswordData, setResetPasswordData] = useState<
    PromptResetPassword['request']['args'] | undefined
  >();
  const [restoreWalletData, setRestoreWalletData] = useState<
    PromptRestoreWallet['request']['args'] | undefined
  >();

  const setModeConnectionDialog = useCallback(
    async (mode: 'signin' | 'signup') => {
      await sendRequest<SetMode>('setMode', {
        mode,
      });
    },
    [sendRequest]
  );

  useRegisterPassword();

  useEffect(
    () =>
      registerHandler<Prompt>('prompt', async ({ type }) => {
        setOpenedForm(type);
        return {};
      }),
    [registerHandler]
  );

  useEffect(
    () =>
      registerHandler<PromptSignupWithUsername>(
        'promptSignupWithUsername',
        async ({
          username,
          isAndroidApp: isAndroidAppArg,
          sport: sportArg,
        }) => {
          setNickname(username);
          setSport(sportArg);
          setIsAndroidApp(isAndroidAppArg);

          // force unmount of previous form
          setOpenedForm(undefined);
          // and remount the new one
          setTimeout(() => setOpenedForm('signup'), 1);

          return {};
        }
      ),
    [registerHandler]
  );

  useEffect(
    () =>
      registerHandler<PromptDeposit>('promptDeposit', async ({ id }) => {
        setDepositId(id);
        setOpenedForm('deposit');
        return {};
      }),
    [registerHandler]
  );

  useEffect(
    () =>
      registerHandler<PasswordForgotten>(
        'passwordForgotten',
        async ({ isMobileApp }) => {
          setMobileApp(isMobileApp || false);
          setOpenedForm('passwordForgotten');
          return {};
        }
      ),
    [registerHandler]
  );

  useEffect(
    () =>
      registerHandler<SignUpMobileView>(
        'signUpMobileView',
        async ({ isAndroidApp: isAndroidAppArg }) => {
          setIsAndroidApp(isAndroidAppArg);
          setOpenedForm('signUpMobileView');
          return {};
        }
      ),
    [registerHandler]
  );

  useEffect(
    () =>
      registerHandler<PromptResetPassword>(
        'promptResetPassword',
        async args => {
          setOpenedForm('resetPassword');
          setResetPasswordData(args);
          return {};
        }
      ),
    [registerHandler]
  );

  useEffect(
    () =>
      registerHandler<PromptRestoreWallet>(
        'promptRestoreWallet',
        async args => {
          setOpenedForm('restoreWallet');
          setRestoreWalletData(args);
          return {};
        }
      ),
    [registerHandler]
  );

  useEffect(() => {
    if (user === undefined && openedForm === undefined) setOpenedForm('signIn');
  }, [user, openedForm]);

  const onSuccess = () => {
    setBackTo(undefined);
    setOpenedForm(undefined);
  };
  const onForgotPassword = () => {
    setBackTo(openedForm);
    setOpenedForm('passwordForgotten');
  };

  const dialogParams = useMemo(() => {
    switch (openedForm) {
      case 'signUpMobileView':
        return { className: `scroll-y-overlay ${openedForm}` };
      case 'signup':
        return { className: `V2WalletForm scroll-y-overlay ${openedForm}` };
      case 'signIn':
        return { className: `V2WalletForm scroll-y-overlay ${openedForm}` };
      case 'passwordForgotten':
        return { className: `V2WalletForm ${backTo ? 'wallet-drawer' : ''}` };
      case 'generateKey':
      case 'generateKeys':
      case 'changePassword':
      case 'deposit':
      case 'resetPassword':
      case 'restoreWallet':
      case 'privateKeyExport':
        return { className: `V2WalletForm wallet-drawer` };
      default:
        return {};
    }
  }, [backTo, openedForm]);

  return (
    <FormsContext.Provider
      value={{ openedForm, close: () => setOpenedForm(undefined) }}
    >
      {openedForm ? (
        <Dialog {...dialogParams}>
          {openedForm === 'generateKey' && (
            <GenerateKey onSuccess={onSuccess} />
          )}
          {openedForm === 'generateKeys' && (
            <GenerateKeys onSuccess={onSuccess} />
          )}
          {openedForm === 'passwordForgotten' && (
            <ForgotPassword
              onSuccess={onSuccess}
              onBack={() => setOpenedForm(backTo)}
              mode={backTo ? 'drawer' : 'modal'}
              isMobileApp={mobileApp}
            />
          )}
          {openedForm === 'changePassword' && (
            <ChangePassword
              onSuccess={onSuccess}
              onForgotPassword={onForgotPassword}
            />
          )}
          {openedForm === 'deposit' && (
            <DepositStarkExchange depositId={depositId} onSuccess={onSuccess} />
          )}
          {openedForm === 'resetPassword' && resetPasswordData && (
            <ResetPassword {...resetPasswordData} onSuccess={onSuccess} />
          )}
          {openedForm === 'restoreWallet' && restoreWalletData && (
            <RestoreWallet {...restoreWalletData} onSuccess={onSuccess} />
          )}
          {openedForm === 'privateKeyExport' && (
            <PrivateKey onSuccess={onSuccess} />
          )}
          {(openedForm === 'signup' || openedForm === 'signUpMobileView') && (
            <SignUp
              nicknameReceived={nickname!}
              sportSelected={sport!}
              onSuccess={onSuccess}
              isAndroidApp={isAndroidApp}
            />
          )}
          {openedForm === 'signIn' && (
            <SignIn
              onSuccess={onSuccess}
              switchToSignUp={() => {
                setModeConnectionDialog('signup');
              }}
            />
          )}
        </Dialog>
      ) : (
        children
      )}
    </FormsContext.Provider>
  );
};

export default FormsContext;
