import { useCallback, useContext } from 'react';

import { MessagingContext, WalletIsLocked } from '@sorare/wallet-shared/';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;

  return useCallback(
    (isLocked: boolean) => {
      sendRequest<WalletIsLocked>('walletIsLocked', { isLocked });
    },
    [sendRequest]
  );
};
