import { useMemo, useState } from 'react';

import { Data, Inputs } from './types';

export default function useFormData<T extends string>(
  inputs: Inputs<T>,
  initialData: Partial<Data<T>>
): [Data<T>, React.Dispatch<React.SetStateAction<Data<T>>>] {
  const emptyData = useMemo(
    () =>
      inputs.reduce((sum, cur) => {
        sum[cur[0]] = '';
        return sum;
      }, {} as Data<T>),
    [inputs]
  );

  const [formData, setFormData] = useState<Data<T>>({
    ...emptyData,
    ...initialData,
  });

  return [formData, setFormData];
}
