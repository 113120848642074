import { useCallback, useContext } from 'react';

import {
  MessagingContext,
  ReturnToWalletSettingsTab,
} from '@sorare/wallet-shared';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;

  return useCallback(
    async () =>
      sendRequest<ReturnToWalletSettingsTab>('returnToWalletSettingsTab', {}),
    [sendRequest]
  );
};
