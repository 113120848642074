import React from 'react';

export interface Props {
  label: string;
  error?: string;
  showLabel?: boolean;
}

export const Label = ({ label, error, showLabel }: Props) => (
  <label
    style={{
      color: error ? 'var(--c-red-600)' : 'inherit',
    }}
    className={error ? 'error' : ''}
    htmlFor={label}
  >
    {showLabel ? label : ''}
    {error && ` ${error}`}
  </label>
);

export default Label;
