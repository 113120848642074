import type { LimitOrder, Signature, Transfer } from '@sorare/crypto';

type IAuthorizationRequest<T, R> = R & { __typename: T; fingerprint: string };

type StarkexTransferAuthorizationRequest = IAuthorizationRequest<
  'StarkexTransferAuthorizationRequest',
  Transfer
>;

type StarkexLimitOrderAuthorizationRequest = IAuthorizationRequest<
  'StarkexLimitOrderAuthorizationRequest',
  LimitOrder
>;

type MangopayWalletTransferAuthorizationRequest = IAuthorizationRequest<
  'MangopayWalletTransferAuthorizationRequest',
  {
    mangopayWalletId: string;
    operationHash: string;
    currency: string;
    amount: number;
    nonce: number;
  }
>;

export type AuthorizationRequest =
  | StarkexTransferAuthorizationRequest
  | StarkexLimitOrderAuthorizationRequest
  | MangopayWalletTransferAuthorizationRequest;

export const isAStarkexTransferAuthorizationRequest = (
  request: AuthorizationRequest
): request is StarkexTransferAuthorizationRequest => {
  return request.__typename === 'StarkexTransferAuthorizationRequest';
};

export const isAStarkexLimitOrderAuthorizationRequest = (
  request: AuthorizationRequest
): request is StarkexLimitOrderAuthorizationRequest => {
  return request.__typename === 'StarkexLimitOrderAuthorizationRequest';
};

export const isAMangopayWalletTransferAuthorizationRequest = (
  request: AuthorizationRequest
): request is MangopayWalletTransferAuthorizationRequest => {
  return request.__typename === 'MangopayWalletTransferAuthorizationRequest';
};

type IAuthorizationApproval = {
  fingerprint: string;
};

type StarkexAuthorizationApproval = {
  nonce: number;
  expirationTimestamp: number;
  signature: Signature;
};

type StarkexTransferAuthorizationApproval = IAuthorizationApproval & {
  starkexTransferApproval: StarkexAuthorizationApproval;
};

export type StarkexLimitOrderAuthorizationApproval = IAuthorizationApproval & {
  starkexLimitOrderApproval: StarkexAuthorizationApproval;
};

type MangopayWalletTransferAuthorizationApproval = IAuthorizationApproval & {
  mangopayWalletTransferApproval: {
    nonce: number;
    signature: Signature;
  };
};

export type AuthorizationApproval =
  | StarkexTransferAuthorizationApproval
  | StarkexLimitOrderAuthorizationApproval
  | MangopayWalletTransferAuthorizationApproval;
