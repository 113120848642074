import React from 'react';

export const Ethereum = () => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      points="5.99995313 19.5439739 11.9963438 11.0900156 5.99817188 14.6336719 -4.6875e-05 11.0900156"
    />
    <polygon
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      points="5.99995312 0 11.9963437 9.9525 5.99995312 13.4980312 0 9.9525"
    />
  </svg>
);

export default Ethereum;
