import { useCallback, useContext } from 'react';

import { MessagingContext, PasswordForgotten } from '@sorare/wallet-shared';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;

  return useCallback(async () => {
    return sendRequest<PasswordForgotten>('passwordForgotten', {});
  }, [sendRequest]);
};
