// https://github.com/gustf/js-levenshtein

function min(d0, d1, d2, bx, ay) {
  const t1 = d0 > d2 ? d2 + 1 : d0 + 1;
  const t2 = bx === ay ? d1 : d1 + 1;

  return d0 < d1 || d2 < d1 ? t1 : t2;
}

export default function levenshtein(aInit, bInit) {
  let a = aInit;
  let b = bInit;

  if (a === b) {
    return 0;
  }

  if (a.length > b.length) {
    const tmp = a;
    a = b;
    b = tmp;
  }

  let la = a.length;
  let lb = b.length;

  while (la > 0 && a.charCodeAt(la - 1) === b.charCodeAt(lb - 1)) {
    la -= 1;
    lb -= 1;
  }

  let offset = 0;

  while (offset < la && a.charCodeAt(offset) === b.charCodeAt(offset)) {
    offset += 1;
  }

  la -= offset;
  lb -= offset;

  if (la === 0 || lb < 3) {
    return lb;
  }

  let x = 0;
  let y;
  let d0;
  let d1;
  let d2;
  let d3;
  let dd;
  let dy;
  let ay;
  let bx0;
  let bx1;
  let bx2;
  let bx3;

  const vector = [];

  for (y = 0; y < la; y += 1) {
    vector.push(y + 1);
    vector.push(a.charCodeAt(offset + y));
  }

  const len = vector.length - 1;

  for (; x < lb - 3; ) {
    bx0 = b.charCodeAt(offset + (d0 = x));
    bx1 = b.charCodeAt(offset + (d1 = x + 1));
    bx2 = b.charCodeAt(offset + (d2 = x + 2));
    bx3 = b.charCodeAt(offset + (d3 = x + 3));
    x += 4;
    dd = x;
    for (y = 0; y < len; y += 2) {
      dy = vector[y];
      ay = vector[y + 1];
      d0 = min(dy, d0, d1, bx0, ay);
      d1 = min(d0, d1, d2, bx1, ay);
      d2 = min(d1, d2, d3, bx2, ay);
      dd = min(d2, d3, dd, bx3, ay);
      vector[y] = dd;
      d3 = d2;
      d2 = d1;
      d1 = d0;
      d0 = dy;
    }
  }

  for (; x < lb; ) {
    bx0 = b.charCodeAt(offset + (d0 = x));
    x += 1;
    dd = x;
    for (y = 0; y < len; y += 2) {
      dy = vector[y];
      dd = min(dy, d0, dd, bx0, vector[y + 1]);
      vector[y] = dd;
      d0 = dy;
    }
  }

  return dd;
}
