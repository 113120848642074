import React from 'react';

const AppleLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="12"
    height="12"
  >
    <g clipPath="url(#prefix__C)" transform="translate(3) scale(.03677)">
      <path d="M591.8 462.4c-1-110.1 90-163 94.1-165.6-51.2-74.9-130.8-85.1-159.2-86.3-67.7-6.9-132.2 39.9-166.6 39.9s-87.4-38.9-143.6-37.8c-73.9 1.1-142 42.9-180.1 109.1-76.7 133-19.6 330.3 55.2 438.4 36.6 52.8 80.1 112.3 137.4 110.1 55.2-2.2 76-35.7 142.6-35.7s85.4 35.7 143.6 34.6c59.3-1.2 96.9-54 133.2-107 41.9-61.3 59.2-120.7 60.2-123.8-1.3-.5-115.6-44.3-116.8-175.9" />
    </g>
    <path d="M20.735 5.111C21.852 3.758 22.606 1.875 22.4 0c-1.61.066-3.56 1.074-4.714 2.427-1.033 1.195-1.941 3.11-1.695 4.95 1.79.139 3.626-.916 4.744-2.266" />
  </svg>
);

export default AppleLogo;
