import React from 'react';

import LoadingBall from 'components/LoadingBall';

const styles = {
  loadingBall: {
    margin: 'auto',
  },
};

export const CenteredLoadingBall = () => (
  <div
    style={{
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <LoadingBall style={styles.loadingBall} />
  </div>
);

export default CenteredLoadingBall;
