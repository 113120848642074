import bcrypt from 'bcryptjs';
import { useCallback, useContext } from 'react';

import { MessagingContext, Salt } from '@sorare/wallet-shared';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;

  return useCallback(
    async (password: string, email?: string) => {
      if (!email) return bcrypt.hashSync(password, 11);

      const {
        result: { salt },
      } = await sendRequest<Salt>('salt', { email });
      return bcrypt.hashSync(password, salt);
    },
    [sendRequest]
  );
};
