import { useContext } from 'react';

import WalletContext from 'contexts/wallet';
import { NoKeyError } from 'lib/errors';

export default () => {
  const { load } = useContext(WalletContext)!;
  return async (password: string) => {
    try {
      const wallet = await load({ password });
      return wallet;
    } catch (e) {
      if (e instanceof NoKeyError) return null;
      throw e;
    }
  };
};
