import React, { useContext } from 'react';

import {
  MessagingContext,
  PromptResetPassword,
  ResetPassword as ResetPasswordRequest,
} from '@sorare/wallet-shared';
import Form, { Data } from 'components/Form';
import useHasher from 'hooks/useHasher';
import { validate, validatePasswordConfirmation } from 'lib/password';

import { Props } from './types';

const fields = [
  [
    'newPassword',
    'password',
    {
      autoComplete: 'new-password',
    },
  ],
  ['confirmNewPassword', 'password'],
] as const;

type Field = (typeof fields)[number][0];

export const ResetPassword = ({
  email,
  nickname,
  resetPasswordToken,
  onSuccess,
}: PromptResetPassword['request']['args'] & Props) => {
  const { sendRequest } = useContext(MessagingContext)!;
  const hasher = useHasher();

  const onSubmit = async ({ newPassword, confirmNewPassword }: Data<Field>) => {
    const differentPasswordsError = validatePasswordConfirmation(
      newPassword,
      confirmNewPassword
    );
    if (differentPasswordsError)
      return {
        newPassword: differentPasswordsError,
        confirmNewPassword: ' ',
      };

    const passwordError = await validate(email, nickname, newPassword);
    if (passwordError) {
      return { newPassword: passwordError };
    }

    const passwordHash = await hasher(newPassword);
    const { error } = await sendRequest<ResetPasswordRequest>('resetPassword', {
      passwordHash,
      resetPasswordToken,
    });

    if (error) {
      if (error.resetPasswordToken) {
        return {
          password: `reset_password_token ${error.resetPasswordToken}`,
        };
      }
      return { password: 'invalid token' };
    }
    return undefined;
  };

  return <Form inputs={fields} onSubmit={onSubmit} onSuccess={onSuccess} />;
};

export default ResetPassword;
