import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export const Dialog = ({ children, className }: Props) => (
  <div className={`dialog ${className || ''}`}>{children}</div>
);

export default Dialog;
