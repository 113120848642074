import { useEffect, useState } from 'react';

import { Deferred } from '@sorare/wallet-shared';

type Handler = () => void;

export default function useDebounce(handler: Handler, disable?: boolean) {
  const [deferred, setDeferred] = useState(new Deferred<boolean>());

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    const reschedule = async () => {
      await deferred.promise;
      timeout = setTimeout(() => setDeferred(new Deferred<boolean>()), 1000);
    };

    reschedule();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [deferred]);

  if (disable) return handler;

  return () => {
    if (!deferred.pending) return;

    deferred.resolve(true);
    handler();
  };
}
