import React, { useContext } from 'react';

import InfoContext from 'contexts/info';

export const ErrorFallback = ({
  message,
}: {
  message?: {
    errorTitle: string;
    errorBody: string;
  };
}) => {
  const { dict } = useContext(InfoContext)!;
  const { errorTrackingTitle: defaultTitle, errorTrackingBody: defaultBody } =
    dict;
  return (
    <div role="alert">
      <p>{message?.errorTitle || defaultTitle}</p>
      <p>{message?.errorBody || defaultBody}</p>
    </div>
  );
};
