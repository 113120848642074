import config from './config.json';

interface Config {
  bankAddress: string | null;
  relayAddress: string | null;
  erc721Address: string | null;
  migratorAddress: string | null;
  starkExchangeAddress: string | null;
  ethMigrationPoolAddress: string | null;
  infuraEndpoint: string | null;
  evervault: {
    teamId: string;
    appId: string;
    ecdhP256Key: string;
    ecdhP256KeyUncompressed: string;
  };
  sorareEncryptionKey: string;
  vaultinumEncryptionKey: string;
}

// eslint-disable-next-line no-restricted-properties
const { hostname, search } = window.location;
const urlParams = new URLSearchParams(search);

const forcedEnv = urlParams.get('forcedEnv');
export const ALLOWED_ORIGIN = urlParams.get('allowedOrigin');

const getEnvConfig = (): Config => {
  if (hostname.match(/sorare.(dev|tech)/)) {
    return config.staging;
  }
  if (hostname.match(/localhost/)) {
    switch (forcedEnv) {
      case 'staging':
      case 'mockprod':
        return config.staging;
      case 'production':
        return config.production;
      default:
        return config.local;
    }
  }
  if (hostname.match(/sorare.netlify.app/)) {
    return config.preview;
  }

  return config.production;
};

const envConfig = getEnvConfig();

export const BANK_ADDRESS =
  envConfig.bankAddress || process.env.VITE_BANK_ADDRESS!;
export const RELAY_ADDRESS =
  envConfig.relayAddress || process.env.VITE_RELAY_ADDRESS!;
export const ERC721_ADDRESS =
  envConfig.erc721Address || process.env.VITE_ERC721_ADDRESS!;
export const MIGRATOR_ADDRESS = envConfig.migratorAddress!;
export const STARK_EXCHANGE_ADDRESS = envConfig.starkExchangeAddress!;
export const ETH_MIGRATION_POOL_ADDRESS = envConfig.ethMigrationPoolAddress!;
export const INFURA_ENDPOINT =
  envConfig.infuraEndpoint || process.env.INFURA_ENDPOINT!;
export const EVERVAULT_CONFIG = envConfig.evervault;
export const ENCRYPTION_KEYS = {
  sorare: envConfig.sorareEncryptionKey,
  vaultinum: envConfig.vaultinumEncryptionKey,
};
