import React from 'react';

const Email = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4.51887V11H12V4.51887L6 8.26887L0 4.51887Z"
      fill="currentColor"
    />
    <path d="M12 2.75V2H0V2.75L6 6.5L12 2.75Z" fill="currentColor" />
  </svg>
);

export default Email;
