import { forwardRef } from 'react';

import { Sport } from '@sorare/wallet-shared';
import AppleLogo from 'assets/oauth/Apple';
import GoogleLogo from 'assets/oauth/Google';
import useRequestOAuth from 'hooks/useRequestOAuth';
import useInfo from 'src/hooks/useInfo';

export enum OAuthMethods {
  GOOGLE = 'google',
  APPLE = 'apple',
}

type Props = {
  forceDisabled?: boolean;
  methods?: OAuthMethods[];
  nickname?: string;
  sport?: Sport;
  acceptTerms: boolean;
  acceptAgeLimit: boolean;
  acceptToShareAll: boolean;
  acceptToShareSpecific: string[];
};

export const NewOAuth = forwardRef<HTMLDivElement, Props>(
  (
    {
      methods = [OAuthMethods.APPLE, OAuthMethods.GOOGLE],
      forceDisabled,
      nickname,
      sport,
      acceptTerms,
      acceptAgeLimit,
      acceptToShareAll,
      acceptToShareSpecific,
    },
    ref
  ) => {
    const { dict } = useInfo();
    const requestOAuth = useRequestOAuth();
    const oAuthParams = {
      signup: true,
      nickname,
      sport,
      acceptTerms,
      acceptAgeLimit,
      acceptToShareAll,
      acceptToShareSpecific,
    };
    return (
      <div className="oAuth" ref={ref}>
        <div className="newOAuthQuickAccess">
          {methods.includes(OAuthMethods.GOOGLE) && (
            <button
              type="button"
              disabled={forceDisabled}
              className="newSignUpFlowButtons"
              onClick={() => {
                requestOAuth({ platform: 'google', ...oAuthParams });
              }}
            >
              <GoogleLogo />
              {dict.signUpGoogle}
            </button>
          )}
          {methods.includes(OAuthMethods.APPLE) && (
            <button
              type="button"
              disabled={forceDisabled}
              className="newSignUpFlowButtons"
              onClick={() => {
                requestOAuth({ platform: 'apple', ...oAuthParams });
              }}
            >
              <AppleLogo />
              {dict.signUpApple}
            </button>
          )}
        </div>
      </div>
    );
  }
);

NewOAuth.displayName = 'NewOAuth';

export default NewOAuth;
