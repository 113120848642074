import React, { useEffect, useState } from 'react';

import Form, { Data } from 'components/Form';
import { currentPassword as currentPasswordField } from 'components/Form/fields';
import useInfo from 'hooks/useInfo';
import useWalletIsLocked from 'hooks/useWalletIsLocked';

import ForgotPassword from './ForgotPassword';

const field = currentPasswordField;
type Field = (typeof field)[0];

interface Props {
  presetErrors?: Record<string, string | null>;
  onSubmit: (result: Data<Field>) => Promise<Partial<Data<Field>> | void>;
}

const WalletIsLocked = ({ onSubmit, presetErrors }: Props) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { dict } = useInfo();
  const walletIsLocked = useWalletIsLocked();

  useEffect(() => {
    walletIsLocked(true);
    return () => walletIsLocked(false);
  }, [walletIsLocked]);

  return (
    <div className="V2WalletForm wallet-drawer">
      {!showForgotPassword && (
        <div>
          <Form
            presetErrors={presetErrors}
            inputs={[field]}
            onSubmit={onSubmit}
            submitLabel={dict.unlock}
          />
          <button
            onClick={() => setShowForgotPassword(true)}
            className="passwordForgottenV2"
            type="button"
          >
            {dict.forgotPassword}
          </button>
        </div>
      )}
      {showForgotPassword && (
        <ForgotPassword
          onSuccess={() => setShowForgotPassword(false)}
          onBack={() => setShowForgotPassword(false)}
          mode="drawer"
        />
      )}
    </div>
  );
};

export default WalletIsLocked;
