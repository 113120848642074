import React, {
  FormEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { Dict } from '@sorare/wallet-shared';
import LoadingBall from 'components/LoadingBall';
import TextInput from 'components/TextInput';
import useDebounce from 'hooks/useDebounce';
import useInfo from 'hooks/useInfo';
import useToggle from 'hooks/useToggle';

import { Data, Inputs } from './types';

export const TOP_LEVEL_FORM_ERROR = Symbol('global form error');

const styles = {
  loadingBall: {
    display: 'inline-block',
  },
};

interface Props<T extends string> {
  inputs: Inputs<T>;
  formData: Data<T>;
  onChange: (formData: Data<T>) => void;
  onSubmit: (result: Data<T>) => Promise<Partial<Data<T>> | void>;
  onCancel?: () => void;
  onSuccess?: () => void;
  children?: ReactElement;
  hideForm?: boolean;
  title?: string | ReactNode;
  submitLabel?: string | ReactNode;
  forceDisabled?: boolean;
  presetErrors?: Record<string, string | null>;
  onResizeOrMove?: (dimension: DOMRectReadOnly) => void;
  additionalScreen?: ReactNode;
}

type FormError = Record<string, string | undefined | null> & {
  [TOP_LEVEL_FORM_ERROR]?: ReactNode;
};

const isKeyOfDict = (
  error: string | undefined | null,
  dict: Dict
): error is keyof Dict => !!error && Object.keys(dict).includes(error);

const Form = <T extends keyof Dict>({
  inputs,
  formData,
  onSubmit,
  onCancel,
  onSuccess,
  onChange,
  children,
  title,
  hideForm,
  submitLabel,
  presetErrors,
  forceDisabled,
  onResizeOrMove,
  additionalScreen,
}: Props<T>) => {
  const [hidden, toggleHidden] = useToggle(!!hideForm);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const currentRef = useRef<HTMLFormElement | null>(null);
  const additionalScreenRef = useRef<HTMLDivElement | null>(null);
  const { dict } = useInfo();
  const [pending, setPending] = useState(false);

  const hasErrors = Object.keys(formErrors).length > 0;
  const handleChange =
    (name: T): React.ChangeEventHandler<HTMLInputElement> =>
    e =>
      onChange({ ...formData, [name]: e.target.value });

  const rawSubmit = async () => {
    setPending(true);
    setFormErrors({});
    const errors = await onSubmit(formData);
    setPending(false);
    if (errors) {
      setFormErrors(errors);
    } else if (onSuccess) onSuccess();
  };

  const submitAfterPrevent = useDebounce(() => {
    rawSubmit();
  });

  const submit = (e: FormEvent) => {
    e.preventDefault();
    submitAfterPrevent();
  };

  const errorMessage = useCallback(
    (error: string | undefined | null) => {
      if (isKeyOfDict(error, dict)) {
        return dict[error];
      }
      if (error) {
        return error;
      }
      return undefined;
    },
    [dict]
  );

  const formIsEmpty = useMemo(() => {
    const emptyInputs = inputs.filter(input => !formData[input[0]]);
    return emptyInputs.length > 0;
  }, [inputs, formData]);

  useEffect(() => {
    if (presetErrors) setFormErrors(presetErrors);
  }, [presetErrors]);

  useEffect(() => {
    if (!onResizeOrMove) {
      return () => {};
    }
    if (!currentRef.current) {
      return () => {};
    }
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        onResizeOrMove(entry.contentRect);
      });
    });
    observer.observe(currentRef.current);
    return () => {
      observer.disconnect();
    };
  }, [onResizeOrMove]);

  useEffect(() => {
    if (!onResizeOrMove) {
      return () => {};
    }
    if (!additionalScreenRef.current) {
      return () => {};
    }
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        onResizeOrMove(entry.contentRect);
      });
    });
    observer.observe(additionalScreenRef.current);
    return () => {
      observer.disconnect();
    };
  }, [onResizeOrMove]);

  if (additionalScreen)
    return <div ref={additionalScreenRef}>{additionalScreen}</div>;

  return (
    <form onSubmit={submit} ref={currentRef}>
      {title && typeof title === 'string' ? <p>{title}</p> : title}
      {hidden && !hasErrors && (
        <div style={{ marginBottom: 16 }}>
          <button type="button" onClick={toggleHidden} className="editForm">
            {dict.edit}
          </button>
        </div>
      )}
      {(!hidden || hasErrors) &&
        inputs.map(input => (
          <TextInput
            key={input[0]}
            type={input[1]}
            onChange={handleChange(input[0])}
            label={dict[input[0]]}
            error={errorMessage(formErrors[input[0]])}
            value={formData[input[0]]}
            {...input[2]}
            {...(input[2]?.placeholder
              ? { placeholder: dict[input[2].placeholder as keyof Dict] }
              : {})}
            showLabel={input[2]?.showLabel}
          />
        ))}
      {children}
      {formErrors[TOP_LEVEL_FORM_ERROR] !== undefined && (
        <div className="mb-16">{formErrors[TOP_LEVEL_FORM_ERROR]}</div>
      )}
      <button type="submit" disabled={pending || formIsEmpty || forceDisabled}>
        {pending ? (
          <LoadingBall style={styles.loadingBall} />
        ) : (
          <span>{submitLabel || dict.submit}</span>
        )}
      </button>
      {onCancel && (
        <button onClick={() => onCancel()} type="button">
          {dict.cancel}
        </button>
      )}
    </form>
  );
};

export default Form;
