import { type Web3, WebSocketProvider } from 'web3';
import type { Transaction } from 'web3-types';

import { INFURA_ENDPOINT } from '../../config';

let web3Provider: WebSocketProvider;

export const getWeb3Provider = () => {
  if (!web3Provider) {
    web3Provider = new WebSocketProvider(
      INFURA_ENDPOINT,
      {},
      {
        delay: 1000,
        autoReconnect: true,
        maxAttempts: 10,
      }
    );
  }
  return web3Provider;
};

export const getGasPrice = async (web3: Web3): Promise<bigint> =>
  web3.eth.getGasPrice();

export const estimateGas = async (
  web3: Web3,
  tx: Transaction
): Promise<bigint> => web3.eth.estimateGas(tx);

export const sendTransaction = async (web3: Web3, tx: Transaction) =>
  web3.eth.sendTransaction(tx);

export const nullAddress = '0x0000000000000000000000000000000000000000';

export const isValidAddress = (address?: string | null) =>
  address && address !== nullAddress;
