import React, { useContext } from 'react';

import { GeneratedKey, MessagingContext } from '@sorare/wallet-shared';
import Form, { Data } from 'components/Form';
import useCreateWallet from 'hooks/useCreateWallet';
import useHasher from 'hooks/useHasher';
import useInfo from 'hooks/useInfo';
import { validate } from 'lib/password';

import { Props } from './types';

const fields = [['password', 'password']] as const;

type Field = (typeof fields)[number][0];

export const GenerateKey = ({ onSuccess }: Props) => {
  const { user, dict } = useInfo();
  const createWallet = useCreateWallet();

  const hasher = useHasher();
  const { sendRequest } = useContext(MessagingContext)!;

  if (!user) return null;

  const { email, nickname } = user;

  const onSubmit = async ({ password }: Data<Field>) => {
    const passwordError = await validate(email, nickname, password);
    if (passwordError) return { password: passwordError };

    const passwordHash = await hasher(password, email);
    const wallet = await createWallet(password, email);

    const { error } = await sendRequest<GeneratedKey>('generatedKey', {
      passwordHash,
      wallet,
    });

    if (error) return error;
    return {};
  };

  return (
    <Form
      inputs={fields}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      title={dict.generateWallet}
    />
  );
};

export default GenerateKey;
