import React, { ComponentProps, ReactElement, ReactNode } from 'react';

import { Dict } from '@sorare/wallet-shared';

import ControlledForm from './ControlledForm';
import { Data, Inputs } from './types';
import useFormData from './useFormData';

interface Props<T extends string> {
  inputs: Inputs<T>;
  onSubmit: (result: Data<T>) => Promise<Partial<Data<T>> | void>;
  onCancel?: () => void;
  onSuccess?: () => void;
  initialValues?: Partial<Data<T>>;
  children?: ReactElement;
  forceDisabled?: boolean;
  title?: string | ReactNode;
  submitLabel?: string | ReactNode;
  presetErrors?: ComponentProps<typeof ControlledForm>['presetErrors'];
  onResizeOrMove?: (dimension: DOMRectReadOnly) => void;
  additionalScreen?: ReactNode;
}

const Form = <T extends keyof Dict>({
  inputs,
  onSubmit,
  onCancel,
  onSuccess,
  initialValues,
  children,
  title,
  submitLabel,
  presetErrors,
  forceDisabled,
  onResizeOrMove,
  additionalScreen,
}: Props<T>) => {
  const [formData, setFormData] = useFormData(inputs, initialValues || {});
  const handleChange = (data: Data<T>) => setFormData(data);

  return (
    <ControlledForm
      formData={formData}
      inputs={inputs}
      onChange={handleChange}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      title={title}
      submitLabel={submitLabel}
      presetErrors={presetErrors}
      onResizeOrMove={onResizeOrMove}
      additionalScreen={additionalScreen}
      forceDisabled={forceDisabled}
    >
      {children}
    </ControlledForm>
  );
};

export default Form;
