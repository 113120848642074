import React, { useContext, useState } from 'react';

import {
  ForgotPassword as ForgotPasswordRequest,
  MessagingContext,
  OnBackForgotPassword,
} from '@sorare/wallet-shared';
import Form, { Data } from 'components/Form';
import useInfo from 'hooks/useInfo';

import { Props } from './types';

const fields = [['email', 'email', { required: true }]] as const;

type Field = (typeof fields)[number][0];

export const ForgotPassword = ({
  onSuccess,
  onBack: doOnBack,
  mode = 'modal',
  isMobileApp,
}: Props) => {
  const { sendRequest } = useContext(MessagingContext)!;
  const [success, setSuccess] = useState(false);

  const { dict } = useInfo();
  const onSubmit = async ({ email }: Data<Field>) => {
    await sendRequest<ForgotPasswordRequest>('forgotPassword', {
      email: email.toLowerCase(),
      remainOpened: true,
    });
    return undefined;
  };

  const onBack = async () => {
    await sendRequest<OnBackForgotPassword>('onBackForgotPassword', {});
    if (doOnBack) doOnBack();
    return undefined;
  };

  const onSuccessCallback = () => {
    if (isMobileApp) {
      setSuccess(true);
    } else {
      onSuccess();
    }
  };

  return (
    <div className={mode}>
      <span className="group-label">{dict.forgotPasswordTitle}</span>

      {success ? (
        <p className="forgot-password-helper">{dict.forgotPasswordEmailSent}</p>
      ) : (
        <>
          <p className="forgot-password-helper">{dict.forgotPasswordHelper}</p>
          <Form
            inputs={fields}
            onSubmit={onSubmit}
            onSuccess={onSuccessCallback}
          />
        </>
      )}

      {!isMobileApp && (
        <div>
          <button
            onClick={() => {
              onBack();
            }}
            className="passwordForgottenV2"
            type="button"
          >
            {dict.navigationBack}
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
