import { useCallback, useContext } from 'react';

import { Keys, MessagingContext } from '@sorare/wallet-shared';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;
  const getKey = useCallback(async () => {
    const { result, error } = await sendRequest<Keys>('keys', {});
    return { result, error };
  }, [sendRequest]);

  return getKey;
};
