import { fromBase64, toBase64 } from '@sorare/wallet-shared';

import { Config } from './types';
import {
  deriveSharedSecret,
  ecPointCompress,
  generateBytes,
  generateECDHKeyPair,
} from './utils/crypto';
import { base64RemovePadding } from './utils/encoding';

export class Encrypter {
  static ivLength = 12;

  static tagLength = 128;

  public static async loadAsync({
    ecdhP256Key,
    ecdhP256KeyUncompressed,
  }: Config) {
    const keyPair = await generateECDHKeyPair();

    const ecdhTeamKey = fromBase64(ecdhP256Key);
    const ecdhPublicKey = keyPair.publicKey;
    const derivedAesKey = await deriveSharedSecret(
      keyPair,
      ecdhP256KeyUncompressed,
      ecdhPublicKey
    );

    return new Encrypter(ecdhTeamKey, ecdhPublicKey, derivedAesKey);
  }

  private static async format(
    keyIv: string,
    ecdhPublicKey: CryptoKey,
    encryptedData: string
  ) {
    const exportableEcdhPublicKey = await window.crypto.subtle.exportKey(
      'raw',
      ecdhPublicKey
    );
    const compressedKey = ecPointCompress(exportableEcdhPublicKey);
    return `ev:Tk9D:${base64RemovePadding(keyIv)}:${base64RemovePadding(
      toBase64(compressedKey)
    )}:${base64RemovePadding(encryptedData)}:$`;
  }

  public ecdhTeamKey: Uint8Array;

  public ecdhPublicKey: CryptoKey;

  public derivedAesKey: ArrayBuffer;

  public constructor(
    ecdhTeamKey: Uint8Array,
    ecdhPublicKey: CryptoKey,
    derivedAesKey: ArrayBuffer
  ) {
    this.ecdhTeamKey = ecdhTeamKey;
    this.ecdhPublicKey = ecdhPublicKey;
    this.derivedAesKey = derivedAesKey;
  }

  public async encrypt(str: string) {
    const keyIv = generateBytes(Encrypter.ivLength);

    const derivedSecretImported = await window.crypto.subtle.importKey(
      'raw',
      this.derivedAesKey,
      {
        name: 'AES-GCM',
      },
      true,
      ['encrypt']
    );

    const encryptedBuffer = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: keyIv,
        tagLength: Encrypter.tagLength,
        additionalData: this.ecdhTeamKey,
      },
      derivedSecretImported,
      new TextEncoder().encode(str)
    );

    return Encrypter.format(
      toBase64(keyIv),
      this.ecdhPublicKey,
      toBase64(new Uint8Array(encryptedBuffer))
    );
  }
}
