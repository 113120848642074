import React from 'react';

const GoogleLogo = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.51252 4.11563C7.96564 3.595 7.27439 3.32812 6.50002 3.32812C5.13064 3.32812 3.97127 4.25125 3.55564 5.49813C3.45064 5.81313 3.38937 6.15 3.38937 6.5C3.38937 6.85 3.44625 7.18688 3.55562 7.50188C3.97125 8.74875 5.13064 9.67188 6.50002 9.67188C7.20876 9.67188 7.80813 9.47938 8.28062 9.16439C8.83187 8.79251 9.20375 8.24563 9.33062 7.59813H6.5V5.625H11.4437C11.4962 5.94438 11.5269 6.2725 11.5269 6.61812C11.5269 8.215 10.9581 9.5625 9.96937 10.4769C9.10312 11.2775 7.91752 11.75 6.50002 11.75C4.44814 11.75 2.67627 10.5688 1.81439 8.85375C1.45564 8.145 1.25 7.34875 1.25 6.5C1.25 5.65125 1.45562 4.855 1.81437 4.14625C2.67625 2.43125 4.44814 1.25 6.50002 1.25C7.91752 1.25 9.10314 1.77063 10.0088 2.61938L8.51252 4.11563Z"
      fill="#101010"
    />
  </svg>
);

export default GoogleLogo;
