import React from 'react';

export const Check = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" rx="30" fill="var(--c-brand-600)" />
    <path
      d="M38.7402 21.7266L25.8984 34.5684L21.2109 29.832C20.9668 29.6367 20.5762 29.6367 20.3809 29.832L18.9648 31.248C18.7695 31.4434 18.7695 31.834 18.9648 32.0781L25.5078 38.5723C25.752 38.8164 26.0938 38.8164 26.3379 38.5723L40.9863 23.9238C41.1816 23.7285 41.1816 23.3379 40.9863 23.0938L39.5703 21.7266C39.375 21.4824 38.9844 21.4824 38.7402 21.7266Z"
      fill="var(--c-static-neutral-100)"
    />
  </svg>
);

export default Check;
