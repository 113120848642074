import { useContext, useEffect } from 'react';

import {
  MessagingContext,
  Password,
  PasswordError,
} from '@sorare/wallet-shared';
import WalletContext from 'contexts/wallet';
import useInfo from 'hooks/useInfo';

export default () => {
  const { registerHandler } = useContext(MessagingContext)!;
  const { dict } = useInfo();
  const { getHashPassword } = useContext(WalletContext)!;

  useEffect(
    () =>
      registerHandler<Password>(
        'password',
        async ({ error, unlockWallet } = {}) => {
          let hash: string | null;
          if (unlockWallet === false) {
            hash = await getHashPassword({
              error:
                error === PasswordError.INVALID_PASSWORD
                  ? dict.passwordIsInvalid
                  : undefined,
              unlockWallet: false,
            });
          } else {
            hash = await getHashPassword();
          }

          return { result: { passwordHash: hash || undefined } };
        }
      ),
    [dict.password, dict.passwordIsInvalid, registerHandler, getHashPassword]
  );
};
