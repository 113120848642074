import React, { InputHTMLAttributes } from 'react';

import useToggle from 'src/hooks/useToggle';

import EyeToggle from './EyeIcon';
import Label, { Props as LabelProps } from './Label';

interface Props extends InputHTMLAttributes<HTMLInputElement>, LabelProps {}

export const TextInput = ({
  type,
  placeholder,
  label,
  error,
  showLabel = true,
  ...rest
}: Props) => {
  const [showPassword, toggleShowPassword] = useToggle(false);
  const revealToggle = type === 'password';
  const inputClassName = revealToggle ? 'hasIcon' : '';
  return (
    <div
      className="input-group"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {label && showLabel && placeholder && (
        <label htmlFor={label} className="label">
          {label}
        </label>
      )}
      <div className="input-wrapper">
        <input
          {...rest}
          type={showPassword ? 'text' : type}
          id={label}
          placeholder={placeholder || label}
          className={error ? `error ${inputClassName}` : inputClassName}
        />
        {revealToggle && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            type="button"
            className="eye-button"
            onClick={toggleShowPassword}
          >
            <EyeToggle visible={showPassword} />
          </button>
        )}
      </div>
      {error && <Label label={label} error={error} />}
    </div>
  );
};

export default TextInput;
