import { useCallback, useContext } from 'react';

import { MessagingContext, RequestOAuth2, Sport } from '@sorare/wallet-shared';

type OAuthRequest = {
  signup: boolean;
  platform: 'google' | 'facebook' | 'apple';
  nickname?: string;
  sport?: Sport;
  acceptTerms?: boolean;
  acceptAgeLimit?: boolean;
  acceptToShareAll?: boolean;
  acceptToShareSpecific?: string[];
};

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;

  return useCallback(
    async ({
      signup,
      platform,
      nickname,
      sport,
      acceptTerms,
      acceptAgeLimit,
      acceptToShareAll,
      acceptToShareSpecific,
    }: OAuthRequest) => {
      return sendRequest<RequestOAuth2>('requestOAuth', {
        signup,
        platform,
        nickname,
        sport,
        acceptTerms,
        acceptAgeLimit,
        acceptToShareAll,
        acceptToShareSpecific,
      });
    },
    [sendRequest]
  );
};
