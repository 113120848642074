import { hexToBytes } from '@ethereumjs/util';
import { Wallet, hdkey } from '@ethereumjs/wallet';
import { generateMnemonic } from '@scure/bip39';
import { wordlist } from '@scure/bip39/wordlists/english';
import { Web3 } from 'web3';
import type { Transaction } from 'web3-types';

import { Deal, SettleDealSignatureType } from '@sorare/wallet-shared';

import {
  accountMappingMessage,
  approveBankMessage,
  approveMigratorMessage,
  depositETHStarkExchangeTx,
  migrateCardsMessage,
  migrateEthMessage,
  settleDealMessage,
} from './contracts';
import {
  estimateGas,
  getGasPrice,
  getWeb3Provider,
  sendTransaction,
} from './utils';

export const PATH = "m/44'/60'/0'/0/0";

const SIGN_MESSAGE_PREFIX = '\x19Sorare Signed Message:\n';

type Account = ReturnType<Web3['eth']['accounts']['privateKeyToAccount']>;

class EthereumWallet {
  public static create() {
    const mnemonic = generateMnemonic(wordlist);

    const wallet = hdkey.EthereumHDKey.fromMnemonic(mnemonic)
      .derivePath(PATH)
      .getWallet();
    return new EthereumWallet(wallet);
  }

  public static load(privateKey: string) {
    const wallet = new Wallet(hexToBytes(privateKey));

    return new EthereumWallet(wallet);
  }

  private wallet: Wallet;

  private loadedWeb3WithAccount?: {
    account: Account;
    web3: Web3;
  };

  public constructor(wallet: Wallet) {
    this.wallet = wallet;
  }

  public get address() {
    return this.wallet.getAddressString() as EthereumAddress;
  }

  public get privateKey() {
    return this.wallet.getPrivateKeyString();
  }

  private get web3WithAccount() {
    if (!this.loadedWeb3WithAccount) {
      const web3Provider = getWeb3Provider();
      const web3 = new Web3(web3Provider);
      const account = web3.eth.accounts.privateKeyToAccount(
        this.wallet.getPrivateKeyString()
      );
      web3.eth.accounts.wallet.add(account);

      this.loadedWeb3WithAccount = {
        account,
        web3,
      };
    }

    return this.loadedWeb3WithAccount;
  }

  private get web3(): Web3 {
    return this.web3WithAccount.web3;
  }

  private get account(): Account {
    return this.web3WithAccount.account;
  }

  public signAccountMapping(mappedAddress: string) {
    return this.sign(accountMappingMessage(this.web3, mappedAddress));
  }

  public depositTransaction(starkKey: string, vaultId: string) {
    return depositETHStarkExchangeTx(
      this.web3,
      starkKey,
      vaultId,
      this.address
    );
  }

  public signSettleDeal(deal: Deal, action: SettleDealSignatureType) {
    return this.sign(settleDealMessage(this.web3, deal, action));
  }

  public signApproveBank(nonce: string) {
    const { message, ...rest } = approveBankMessage(this.web3, nonce);
    const signature = this.sign(message);
    return { signature, ...rest };
  }

  public signApproveMigrator(nonce: string | number) {
    const { message, ...rest } = approveMigratorMessage(this.web3, nonce);
    const signature = this.sign(message);
    return { signature, ...rest };
  }

  public signMigrateCards(cardIds: string[], expirationBlock: string | number) {
    const message = migrateCardsMessage(this.web3, cardIds, expirationBlock);
    return this.sign(message);
  }

  public signMigrateEth(dealId: string | number, sendAmountInWei: bigint) {
    return this.sign(
      migrateEthMessage(this.web3, dealId, this.address, sendAmountInWei)
    );
  }

  public signMessage(message: string) {
    return this.sign(SIGN_MESSAGE_PREFIX + message);
  }

  public async getBalance() {
    return this.web3.eth.getBalance(this.address);
  }

  public async getGasPrice() {
    return getGasPrice(this.web3);
  }

  public async estimateGas(tx: Transaction) {
    return estimateGas(this.web3, tx);
  }

  public async sendTransaction(tx: Transaction) {
    return sendTransaction(this.web3, tx);
  }

  private sign(message: string) {
    return this.account.sign(this.web3.utils.soliditySha3(message)!).signature;
  }
}

export default EthereumWallet;
