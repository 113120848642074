import React, { useContext, useEffect, useState } from 'react';

import CenteredLoadingBall from 'components/CenteredLoadingBall';
import InfoContext from 'contexts/info';
import WalletContext from 'contexts/wallet';
import { ETH_ASSET_QUANTUM } from 'lib/ethereum/contracts';

import Form from './form';

interface Props {
  depositId?: string;
  onSuccess: () => void;
}

export const DepositStarkExchange = ({ onSuccess, depositId }: Props) => {
  const { dict } = useContext(InfoContext)!;
  const { load, wallet } = useContext(WalletContext)!;
  const [info, setInfo] = useState<{
    balance: bigint;
    gasPrice: bigint;
  } | null>(null);

  const [gas, setGas] = useState<bigint | undefined>(undefined);

  useEffect(() => {
    const loadWallet = async () => {
      await load();
    };

    if (!wallet) loadWallet();
  }, [load, wallet]);

  useEffect(() => {
    const getInfo = async () => {
      if (wallet && !info) {
        const { ethereumWallet } = wallet;

        const [balance, gasPrice] = await Promise.all([
          ethereumWallet.getBalance(),
          ethereumWallet.getGasPrice(),
        ]);

        setInfo({ balance, gasPrice });
      }
    };

    getInfo();
  });

  useEffect(() => {
    const getGas = async () => {
      if (!gas && wallet?.starkwareWallet && info && info.balance !== 0n) {
        const { ethereumWallet } = wallet;

        try {
          const computedGas = await ethereumWallet.estimateGas({
            ...ethereumWallet.depositTransaction(
              wallet.starkwareWallet.publicKeyX,
              '1'
            ),
            value: ETH_ASSET_QUANTUM,
          });
          setGas(computedGas);
        } catch (e) {
          if (!(e as Error).message?.includes('insufficient funds')) {
            throw e;
          }
        }
      }
    };

    getGas();
  });

  if (!wallet?.ethereumWallet || !info) return <CenteredLoadingBall />;

  if (info.balance === 0n || !gas)
    return <div className="helper">{dict.noFundsAvailable}</div>;

  return (
    <Form
      {...info}
      gas={gas}
      wallet={wallet}
      depositId={depositId}
      onSuccess={onSuccess}
    />
  );
};

export default DepositStarkExchange;
