import React, { forwardRef } from 'react';

import { Sport } from '@sorare/wallet-shared';
import apple from 'assets/oauth/apple.png';
import facebook from 'assets/oauth/facebook.png';
import google from 'assets/oauth/google.png';
import useInfo from 'hooks/useInfo';
import useRequestOAuth from 'hooks/useRequestOAuth';

export enum OAuthMethods {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

type Props = {
  methods?: OAuthMethods[];
  signup: boolean;
  nickname?: string;
  sport?: Sport;
  acceptTerms?: boolean;
  acceptAgeLimit?: boolean;
  acceptToShareAll?: boolean;
  acceptToShareSpecific?: string[];
};

export const OAuth = forwardRef<HTMLDivElement, Props>(
  (
    {
      methods = [
        OAuthMethods.APPLE,
        OAuthMethods.FACEBOOK,
        OAuthMethods.GOOGLE,
      ],
      signup,
      nickname,
      sport,
      acceptTerms,
      acceptAgeLimit,
      acceptToShareAll,
      acceptToShareSpecific,
    },
    ref
  ) => {
    const { dict } = useInfo();

    const oAuthParams = {
      signup,
      nickname,
      sport,
      acceptTerms,
      acceptAgeLimit,
      acceptToShareAll,
      acceptToShareSpecific,
    };

    const requestOAuth = useRequestOAuth();
    return (
      <div className="oAuth" ref={ref}>
        <div className="oAuthQuickAccessTitle">{dict.orQuickAccess}</div>
        <div className="oAuthQuickAccess">
          {methods.includes(OAuthMethods.GOOGLE) && (
            <button
              type="button"
              onClick={() => {
                requestOAuth({ platform: 'google', ...oAuthParams });
              }}
            >
              <img src={google} alt="Google" />
            </button>
          )}
          {methods.includes(OAuthMethods.APPLE) && (
            <button
              type="button"
              onClick={() => {
                requestOAuth({ platform: 'apple', ...oAuthParams });
              }}
            >
              <img src={apple} alt="Apple" />
            </button>
          )}
          {methods.includes(OAuthMethods.FACEBOOK) && (
            <button
              type="button"
              onClick={() => {
                requestOAuth({
                  platform: 'facebook',
                  ...oAuthParams,
                });
              }}
            >
              <img src={facebook} alt="Facebook" />
            </button>
          )}
        </div>
      </div>
    );
  }
);

OAuth.displayName = 'OAuth';

export default OAuth;
