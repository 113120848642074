import React, {
  AnchorHTMLAttributes,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  MessagingContext,
  SignUp as SignUpRequest,
  Sport,
} from '@sorare/wallet-shared';
import EmailLogo from 'assets/oauth/Email';
import Form, { Data } from 'components/Form';
import useCreateWallet from 'hooks/useCreateWallet';
import useHasher from 'hooks/useHasher';
import useInfo from 'hooks/useInfo';
import useRequestPlaceholderResize from 'hooks/useRequestPlaceholderResize';
import useToggle from 'hooks/useToggle';
import { validate } from 'lib/password';
import NewOAuth, { OAuthMethods } from 'src/components/NewOAuth';

import { Partner, PartnersList } from '../PartnersList';
import { Props } from '../types';

const fields = [
  ['email', 'email', { required: true }],
  ['password', 'password', { required: true, autoComplete: 'new-password' }],
] as const;

type Field = (typeof fields)[number][0];

type NonMobileProps = Props & { isAndroidApp: boolean };

type MobileProps = Props & { switchToSignIn?: never } & {
  isAndroidApp: boolean;
};

type FormProps = NonMobileProps | MobileProps;

type FinalProps = {
  nicknameReceived: string;
  sportSelected: Sport;
} & FormProps;

const buildAnchorParameters = (
  url: string,
  useDeepLink: boolean
): AnchorHTMLAttributes<unknown> => {
  if (useDeepLink) {
    return {
      href: `sorare://external-link?url=${encodeURIComponent(url)}`,
    };
  }
  return {
    href: url,
    target: '_blank',
    rel: 'noreferrer',
  };
};

const resolveTermsAndConditionsAndPolicyLinks = (
  src: string,
  useDeepLink: boolean
): (string | ReactNode)[] => {
  const matchers =
    /<terms>(?<terms>[^<]+)<\/terms>|<privacy>(?<privacy>[^<]+)<\/privacy>/g;
  const result = [];
  let match = matchers.exec(src);
  let previousIndex = 0;
  let linkCounter = 0;
  while (match) {
    if (previousIndex < match.index) {
      result.push(src.substring(previousIndex, match.index));
    }
    if (match.groups) {
      if (match.groups.terms) {
        result.push(
          <a
            key={`generated-link-${linkCounter}`}
            {...buildAnchorParameters(
              'https://sorare.com/terms_and_conditions',
              useDeepLink
            )}
          >
            {match.groups.terms}
          </a>
        );
        linkCounter += 1;
      }
      if (match.groups.privacy) {
        result.push(
          <a
            key={`generated-link-${linkCounter}`}
            {...buildAnchorParameters(
              'https://sorare.com/privacy_policy',
              useDeepLink
            )}
          >
            {match.groups.privacy}
          </a>
        );
        linkCounter += 1;
      }
    }
    previousIndex = matchers.lastIndex;
    match = matchers.exec(src);
  }
  result.push(src.substring(previousIndex));
  return result;
};

export const SignUp = ({
  nicknameReceived,
  sportSelected,
  isAndroidApp,
  onSuccess,
}: FinalProps) => {
  const createWallet = useCreateWallet();
  const hasher = useHasher();
  const [displayFields, setDisplayFields] = useState(false);
  const { sendRequest } = useContext(MessagingContext)!;

  const onResizeOrMove = useRequestPlaceholderResize();

  const { dict } = useInfo();

  const oAuthRef = useRef<HTMLDivElement>(null);
  const oAuthMethods = isAndroidApp
    ? [OAuthMethods.GOOGLE]
    : [OAuthMethods.GOOGLE, OAuthMethods.APPLE];

  const effectiveOnResizeOrMove = useCallback(
    (dimension: DOMRectReadOnly) => {
      if (!oAuthRef.current) {
        return onResizeOrMove(dimension);
      }
      const oAuthDimension = oAuthRef.current.getBoundingClientRect();
      return onResizeOrMove({
        ...dimension,
        width: Math.max(dimension.width, oAuthDimension.width),
        height: dimension.height + oAuthDimension.height,
      });
    },
    [onResizeOrMove]
  );

  const [approvedTcs, toggleApprovedTcs] = useToggle(false);
  const [approvedAgeLimit, toggleApprovedAge] = useToggle(false);

  const [agreeToShareAll, setAgreeToShareAll] = useState<boolean>(false);
  const [agreeToShareSpecific, setAgreeToShareSpecific] = useState<Partner[]>(
    []
  );

  const [showPartnersList, setShowPartnersList] = useState(false);

  const [submittedWithNoApproval, setSubmittedWithNoApproval] = useState(false);

  const onEffectiveSubmit = useCallback(
    async ({ password, email }: Data<Field>) => {
      if (!(approvedTcs && approvedAgeLimit)) {
        setSubmittedWithNoApproval(true);
        return {};
      }
      const passwordError = await validate(email, nicknameReceived, password);
      if (passwordError) return { password: passwordError };

      const wallet = await createWallet(password, email);
      const passwordHash = await hasher(password);

      const signUpRequestArgs: SignUpRequest['request']['args'] = {
        email,
        nickname: nicknameReceived,
        passwordHash,
        wallet,
        acceptTerms: approvedTcs,
        acceptAgeLimit: approvedAgeLimit,
        acceptPrivacyPolicy: approvedTcs,
        agreedToReceiveOffersFromPartners: agreeToShareAll,
        agreedToReceiveOffersFromPartnersSpecific: agreeToShareSpecific,
        mobile: isAndroidApp,
      };

      const { error } = await sendRequest<SignUpRequest>(
        'signUp',
        signUpRequestArgs
      );

      if (error) return error;
      return undefined;
    },
    [
      approvedTcs,
      approvedAgeLimit,
      nicknameReceived,
      createWallet,
      hasher,
      agreeToShareAll,
      agreeToShareSpecific,
      isAndroidApp,
      sendRequest,
    ]
  );

  const onBack = useCallback(
    () => setShowPartnersList(false),
    [setShowPartnersList]
  );

  const iAgreeToTermsAndConditions = useMemo<(string | ReactNode)[]>(
    () =>
      resolveTermsAndConditionsAndPolicyLinks(
        dict.iAgreeToTermsAndConditions,
        false
      ),
    [dict.iAgreeToTermsAndConditions]
  );

  const youMustApproveTcs = submittedWithNoApproval && !approvedTcs;
  const youMustApproveAgeLimit = submittedWithNoApproval && !approvedAgeLimit;

  const isPartiallyAgreeToShare = agreeToShareSpecific.length > 0;

  const handleChangeAgreeToShareAll = () => {
    const newAgreeToShareAll = !agreeToShareAll;
    if (newAgreeToShareAll) {
      setAgreeToShareAll(true);
      setAgreeToShareSpecific([Partner.EPL]);
    } else {
      setAgreeToShareAll(false);
      setAgreeToShareSpecific([]);
    }
  };

  const handlePartnersList = (partnersListInput: Partner[]) => {
    setAgreeToShareSpecific(partnersListInput);
    setShowPartnersList(false);
    setAgreeToShareAll(false);
  };

  const agreeToShareLabel = (src: string) => {
    const matchers = /<partnerslist>(?<partnerslist>[^<]+)<\/partnerslist>/g;
    const result = [];
    let match = matchers.exec(src);
    let previousIndex = 0;
    let linkCounter = 0;

    while (match) {
      if (previousIndex < match.index) {
        result.push(src.substring(previousIndex, match.index));
      }
      if (match.groups) {
        if (match.groups.partnerslist) {
          result.push(
            <button
              className="inline-button"
              type="button"
              key={`generated-link-${linkCounter}`}
              onClick={() => setShowPartnersList(true)}
            >
              {match.groups.partnerslist}
            </button>
          );
          linkCounter += 1;
        }
      }
      previousIndex = matchers.lastIndex;
      match = matchers.exec(src);
    }
    result.push(src.substring(previousIndex));
    return result;
  };

  useEffect(() => {
    const element = document.getElementsByClassName('dialog')[0];
    document.body?.classList.add('signUpBodyOverrides');
    element?.classList.add('signUpBodyOverrides');

    return () => {
      element?.classList.remove('signUpBodyOverrides');
      document.body?.classList.remove('signUpBodyOverrides');
    };
  });

  return (
    <Form
      inputs={displayFields ? fields : []}
      onSubmit={async args => {
        if (!(approvedTcs && approvedAgeLimit)) {
          setSubmittedWithNoApproval(true);
          return {};
        }

        if (displayFields) {
          return onEffectiveSubmit(args);
        }
        setDisplayFields(true);
        return {};
      }}
      onSuccess={onSuccess}
      submitLabel={
        displayFields ? (
          dict.signUp
        ) : (
          <div className="signUpWithEmail">
            <EmailLogo />
            {dict.signUpEmail}
          </div>
        )
      }
      onResizeOrMove={effectiveOnResizeOrMove}
      additionalScreen={
        showPartnersList && (
          <PartnersList
            onBack={onBack}
            onSubmit={handlePartnersList}
            agreeToShare={agreeToShareAll}
            agreeToShareSpecific={agreeToShareSpecific}
          />
        )
      }
      forceDisabled={!(approvedTcs && approvedAgeLimit)}
    >
      <>
        {!displayFields && (
          <div className="signUp additionalFieldsWrapper">
            <div className="signUp additionalFields">
              <h1>{dict.required}</h1>
              <div>
                <label
                  htmlFor="approveTcs"
                  className={youMustApproveTcs ? 'error' : ''}
                >
                  <input
                    id="approveTcs"
                    type="checkbox"
                    checked={approvedTcs}
                    onChange={toggleApprovedTcs}
                  />
                  <span>{iAgreeToTermsAndConditions}</span>
                </label>
                {youMustApproveTcs && (
                  <p className="error">
                    {dict.youMustAcceptTermsAndConditions}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="approveAgeLimit"
                  className={youMustApproveAgeLimit ? 'error' : ''}
                >
                  <input
                    id="approveAgeLimit"
                    type="checkbox"
                    checked={approvedAgeLimit}
                    onChange={toggleApprovedAge}
                  />
                  <span>{dict.iAgreeToAgeLimit}</span>
                </label>
                {youMustApproveAgeLimit && (
                  <p className="error">{dict.youMustAcceptAgeLimit}</p>
                )}
              </div>
            </div>
            <div className="signUp additionalFields">
              <h1>{dict.optional}</h1>
              <div>
                <label htmlFor="agreeToShare">
                  <input
                    type="checkbox"
                    id="agreeToShare"
                    checked={agreeToShareAll || isPartiallyAgreeToShare}
                    onChange={handleChangeAgreeToShareAll}
                    {...(!agreeToShareAll && { className: 'partially' })}
                  />
                  <span>{agreeToShareLabel(dict.iAgreeToShareV2)}</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {!displayFields && (
          <>
            <NewOAuth
              methods={oAuthMethods}
              nickname={nicknameReceived}
              sport={sportSelected}
              acceptTerms={approvedTcs}
              acceptAgeLimit={approvedAgeLimit}
              acceptToShareAll={agreeToShareAll}
              acceptToShareSpecific={agreeToShareSpecific}
              forceDisabled={!(approvedTcs && approvedAgeLimit)}
            />
            <div className="OAuthSeparator">
              <span />
              {dict.oAuthSeparator}
              <span />
            </div>
          </>
        )}
      </>
    </Form>
  );
};
