import {
  type LimitOrder,
  type Transfer,
  exportPublicKey,
  generateKey,
  signLimitOrder,
  signMany,
  signMessage,
  signTransfer,
} from '@sorare/crypto';

class StarkwareWallet {
  public static create() {
    const key = generateKey();

    return new StarkwareWallet(key);
  }

  public static load(privateKey: string) {
    const key = privateKey;
    return new StarkwareWallet(key);
  }

  private key: string;

  public constructor(key: string) {
    this.key = key;
  }

  public get publicKey() {
    return exportPublicKey(this.key, true);
  }

  public get publicKeyX() {
    return `0x${this.publicKey
      // Strip leading /0x0[23]/ to get X component
      .slice(4)}`;
  }

  public get privateKey() {
    return this.key;
  }

  public signLimitOrder(order: LimitOrder) {
    return signLimitOrder(this.privateKey, order);
  }

  public signTransfer(transfer: Transfer) {
    return signTransfer(this.privateKey, transfer);
  }

  public signPaymentIntent(id: string, weiAmount: string) {
    return signMessage(this.privateKey, `${id}:${weiAmount}`);
  }

  public signWalletChallenge(challenge: string) {
    return signMessage(this.privateKey, `WALLET_CHALLENGE:${challenge}`);
  }

  public signMany(values: string[]) {
    return signMany(this.privateKey, values);
  }

  public signMangopayWalletTransferAuthorizationRequest(request: {
    mangopayWalletId: string;
    operationHash: string;
    currency: string;
    amount: number;
    nonce: number;
  }) {
    return signMessage(
      this.privateKey,
      [
        request.mangopayWalletId,
        request.operationHash,
        request.currency,
        request.amount,
        request.nonce,
      ].join(':')
    );
  }
}

export default StarkwareWallet;
