import { bytesToHex, stripHexPrefix } from '@ethereumjs/util';

import fetch from 'lib/fetch';
import levenshtein from 'lib/levenshtein';

const messages = {
  pwned: 'pwned',
  levenshtein: 'levenshtein',
  length: 'length',
  differentPasswords: 'differentPasswords',
};

type ValidationResult = string | null;

export const validatePwned = async (
  password: string
): Promise<ValidationResult> => {
  const bytes = new TextEncoder().encode(password);
  const hashBytes = await crypto.subtle.digest({ name: 'SHA-1' }, bytes);
  const hashHex = stripHexPrefix(bytesToHex(new Uint8Array(hashBytes)));
  const prefix = hashHex.slice(0, 5);
  const suffix = hashHex.slice(5);

  try {
    const response = await fetch(
      `https://api.pwnedpasswords.com/range/${prefix}`,
      {
        mode: 'cors',
      }
    );

    if (!response.ok) return null;
    const data = await response.text();
    const pwned = data.search(suffix.toUpperCase()) > -1;
    return pwned ? messages.pwned : null;
  } catch (e) {
    return null;
  }
};

const validateDistance = (email: string, nickname: string, password: string) =>
  levenshtein(email, password) < 4 ||
  levenshtein(email.split('@')[0], password) < 4 ||
  levenshtein(nickname, password) < 4
    ? messages.levenshtein
    : null;

export const validateLength = (password: string) =>
  password.length < 6 ? messages.length : null;

export const validatePasswordConfirmation = (
  password: string,
  confirmedPassword: string
) => (password !== confirmedPassword ? messages.differentPasswords : null);

export const validate = async (
  email: string,
  nickname: string,
  password: string
): Promise<ValidationResult> =>
  validateLength(password) ||
  validateDistance(email, nickname, password) ||
  validatePwned(password);
