import React, {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  GenerateKey,
  MessagingContext,
  SignIn as SignInRequest,
} from '@sorare/wallet-shared';
import Logo from 'atoms/icons/Logo';
import Form, { Data } from 'components/Form';
import OAuth from 'components/OAuth';
import useCreateWallet from 'hooks/useCreateWallet';
import useHasher from 'hooks/useHasher';
import useInfo from 'hooks/useInfo';
import useLoadWallet from 'hooks/useLoadWallet';
import usePasswordForgotten from 'hooks/usePasswordForgotten';
import useRequestPlaceholderResize from 'hooks/useRequestPlaceholderResize';

import { TOP_LEVEL_FORM_ERROR } from './Form/ControlledForm';

const fields = [
  [
    'email',
    'email',
    {
      autoComplete: 'email',
    },
  ],
  [
    'password',
    'password',
    {
      autoComplete: 'current-password',
      name: 'password',
    },
  ],
] as const;

type Field = (typeof fields)[number][0];

interface Props {
  onSuccess: () => void;
  switchToSignUp?: () => void;
}

type FormProps = {
  onSuccess: () => void;
  switchToSignUp?: () => void;
} & Required<Pick<ComponentProps<typeof Form>, 'onSubmit' | 'onResizeOrMove'>>;

const SignInForm = ({
  onSuccess,
  switchToSignUp,
  onSubmit,
  onResizeOrMove,
}: FormProps) => {
  const { dict } = useInfo();

  const oAuthRef = useRef<HTMLDivElement>(null);

  const effectiveOnResizeOrMove = useCallback(
    (dimension: DOMRectReadOnly) => {
      if (!oAuthRef.current) {
        return onResizeOrMove(dimension);
      }
      const oAuthDimension = oAuthRef.current.getBoundingClientRect();
      return onResizeOrMove({
        ...dimension,
        width: Math.max(dimension.width, oAuthDimension.width),
        height: dimension.height + oAuthDimension.height,
      });
    },
    [onResizeOrMove]
  );

  const passwordForgotten = usePasswordForgotten();

  return (
    <>
      <Form
        inputs={fields}
        onSuccess={onSuccess}
        onResizeOrMove={effectiveOnResizeOrMove}
        onSubmit={onSubmit}
        submitLabel={dict.signIn}
        title={
          <div className="form-header">
            <Logo width={120} />
          </div>
        }
      />
      <div ref={oAuthRef}>
        <button
          type="button"
          onClick={() => {
            passwordForgotten();
          }}
          className="passwordForgottenV2"
        >
          {dict['passwordForgotten?']}
        </button>
        <OAuth signup={false} />
        <div className="switch-container">
          <span>{dict.dontHaveAccount}</span>
          <button type="button" onClick={switchToSignUp}>
            {dict.signUp}
          </button>
        </div>
      </div>
    </>
  );
};

export const SignIn = ({ onSuccess, switchToSignUp }: Props) => {
  const hasher = useHasher();
  const createWallet = useCreateWallet();
  const loadWallet = useLoadWallet();
  const [password, setPassword] = useState<string | null>(null);
  const { sendRequest, registerHandler } = useContext(MessagingContext)!;
  const { dict, setUser } = useInfo();

  useEffect(
    () =>
      registerHandler<GenerateKey>('generateKey', async ({ email }) => {
        if (!password) return {};

        const wallet = await createWallet(password, email);

        return {
          result: {
            wallet,
          },
        };
      }),
    [createWallet, password, registerHandler]
  );

  const onResizeOrMove = useRequestPlaceholderResize();

  const onSubmit = async (data: Data<Field>) => {
    const { email, password: pwd } = data;
    const passwordHash = await hasher(pwd, email);

    setPassword(pwd);

    const { result, error } = await sendRequest<SignInRequest>('signIn', {
      email,
      passwordHash,
      otpAttempt: '',
    });

    if (error === 'invalid') {
      setPassword(null);
      return { email: dict.invalidCredentials };
    }
    if (error === 'unconfirmed') {
      return {
        [TOP_LEVEL_FORM_ERROR]: (
          <div className="warning">
            <b>{dict.emailUnconfirmedTitle}</b>
            <p className="text-14-20 mt-4 mb-0">{dict.emailUnconfirmedBody}</p>
          </div>
        ),
      };
    }

    if (error === 'rate-limit') {
      return { email: dict.tooManyLoginAttempts };
    }
    if (error) {
      return { email: error };
    }

    setUser(result);
    loadWallet(pwd);

    return undefined;
  };

  return (
    <SignInForm
      onSuccess={onSuccess}
      onSubmit={onSubmit}
      onResizeOrMove={onResizeOrMove}
      switchToSignUp={switchToSignUp}
    />
  );
};

export default SignIn;
