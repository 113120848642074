import { useContext } from 'react';

import {
  MessagingContext,
  PrepareEthDeposit,
  Transaction,
} from '@sorare/wallet-shared';
import { ControlledForm, Data, useFormData } from 'components/Form';
import InfoContext from 'contexts/info';
import Wallet from 'lib/wallet';

import { gWeiToWei } from './utils';

interface Props {
  depositId?: string;
  wallet: Wallet;
  balance: bigint;
  gas: bigint;
  gasPrice: bigint;
  onSuccess: () => void;
}

const fields = [
  [
    'gasPrice',
    'number',
    { placeholder: 'gasPricePlaceholder', step: 'any', min: 0, max: 500 },
  ],
] as const;

type Field = (typeof fields)[number][0];

const oneEthBI = BigInt('0xde0b6b3a7640000');

const FACTOR = 100_000;
const FACTOR_BI = BigInt(FACTOR);

const AmountWithConversion = ({
  value,
  currency,
  ethRate,
}: {
  value: bigint;
  currency: { symbol: string };
  ethRate: number;
}) => {
  const formatToEth = (bigNumber: bigint) =>
    (Number((bigNumber * FACTOR_BI) / oneEthBI) / FACTOR).toFixed(4);

  const formatToFiat = (bigNumber: bigint) =>
    (
      Number((bigNumber * BigInt(Math.round(FACTOR * ethRate))) / oneEthBI) /
      FACTOR
    ).toFixed(2);
  return (
    <div className="amount-with-conversion">
      <span>{formatToEth(value)}&nbsp;ETH</span>
      <span className="exponent">
        &#8776;&nbsp;{currency.symbol}&nbsp;{formatToFiat(value)}
      </span>
    </div>
  );
};

export const DepositStarkExchangeForm = ({
  depositId,
  wallet,
  balance,
  gas,
  gasPrice: proposedGasPrice,
  onSuccess,
}: Props) => {
  const initialValues = {
    gasPrice: (Number(proposedGasPrice) / 1e9)
      .toFixed(9)
      .replace(/(\.[0-9]+[1-9])0+$/, '$1'),
  };

  const [data, setData] = useFormData<Field>(fields, initialValues);
  const { sendRequest } = useContext(MessagingContext)!;
  const {
    dict,
    info: { currency, ethRate },
  } = useContext(InfoContext)!;

  const { ethereumWallet, starkwareWallet } = wallet;

  const txInfo = (gasPriceInGwei: string) => {
    const gasPrice = gWeiToWei(gasPriceInGwei);
    const fee = gasPrice * BigInt(gas);
    const totalFees = fee + gasPrice * 21_000n;
    const value = BigInt(balance) - totalFees;
    const balanceBI = BigInt(balance);

    return { value, gasPrice, balanceBI, totalFees };
  };

  const onSubmit = async ({ gasPrice: gasPriceInWei }: Data<Field>) => {
    if (starkwareWallet && ethereumWallet) {
      const starkKey = starkwareWallet.publicKeyX;
      const { value, gasPrice } = txInfo(gasPriceInWei);

      try {
        const { result } = await sendRequest<PrepareEthDeposit>(
          'prepareEthDeposit',
          {
            weiAmount: value,
          }
        );
        if (!result) return { gasPrice: 'unable to fetch deposit payload' };

        const tx = {
          ...ethereumWallet.depositTransaction(
            starkKey,
            result.vaultId.toString()
          ),
          value: result.weiAmount.toString(),
          gasPrice,
          gas: gas + 21_000n,
        };

        const hash = (await ethereumWallet.sendTransaction(tx))
          .transactionHash as string;
        await sendRequest<Transaction>('transaction', {
          hash,
          depositId,
        });

        onSuccess();
        return undefined;
      } catch (error) {
        return { gasPrice: (error as Error).message };
      }
    } else {
      return undefined;
    }
  };

  const { totalFees, value, balanceBI } = txInfo(data.gasPrice);

  const canDeposit = !(value < 0n);

  return (
    <>
      <div className="summary-table">
        <div className="summary-line">
          <span>{dict.availableAmountForDeposit}</span>
          <AmountWithConversion
            currency={currency}
            ethRate={ethRate}
            value={balanceBI}
          />
        </div>
        {canDeposit && (
          <>
            <div className="summary-line">
              <span>{dict.transactionCost}</span>
              <AmountWithConversion
                currency={currency}
                ethRate={ethRate}
                value={totalFees}
              />
            </div>

            <div className="summary-line" style={{ fontWeight: 600 }}>
              <span>{dict.depositAmount}</span>
              <AmountWithConversion
                currency={currency}
                ethRate={ethRate}
                value={value}
              />
            </div>
          </>
        )}
      </div>
      <span>{dict.depositAvailableSoon}</span>
      {canDeposit ? (
        <ControlledForm
          inputs={fields}
          formData={data}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          onChange={setData}
          submitLabel={dict.depositFundsSubmit}
          hideForm
        />
      ) : (
        <div className="helper">{dict.insufficientFundsForDeposit}</div>
      )}
    </>
  );
};

export default DepositStarkExchangeForm;
